// @flow
import {
  PAGE_TYPE_INTERNAL,
  PAGE_TYPE_EXTERNAL,
  BACKGROUND_TYPE_IMAGE,
  BACKGROUND_TYPE_VIDEO,
  BACKGROUND_TYPE_SOLID_COLOR
} from '../config/consts';

// MAIN BACKGROUND IMAGES
import mainImg_s from '../images/about/main_s.jpg';
import mainImg_m from '../images/about/main_m.jpg';
import mainImg_l from '../images/about/main_l.jpg';
import mainImg_xl from '../images/about/main_xl.jpg';
import expertiseImg_s from '../images/about/expertise_s.jpg';
import expertiseImg_m from '../images/about/expertise_m.jpg';
import expertiseImg_l from '../images/about/expertise_l.jpg';
import expertiseImg_xl from '../images/about/expertise_xl.jpg';
import clientsImg_s from '../images/about/clients_s.jpg';
import clientsImg_m from '../images/about/clients_m.jpg';
import clientsImg_l from '../images/about/clients_l.jpg';
import clientsImg_xl from '../images/about/clients_xl.jpg';
import referencesImg_s from '../images/about/references_s.jpg';
import referencesImg_m from '../images/about/references_m.jpg';
import referencesImg_l from '../images/about/references_l.jpg';
import referencesImg_xl from '../images/about/references_xl.jpg';
import teamImg_s from '../images/about/team_s.jpg';
import teamImg_m from '../images/about/team_m.jpg';
import teamImg_l from '../images/about/team_l.jpg';
import teamImg_xl from '../images/about/team_xl.jpg';

// DROXIC LOGO

// EXPERTISE DIALOG BACKGROUNDS
import digital_platforms_s from '../images/expertise/digital_platforms_s.jpg';
import digital_platforms_m from '../images/expertise/digital_platforms_m.jpg';
import digital_platforms_l from '../images/expertise/digital_platforms_l.jpg';
import digital_platforms_xl from '../images/expertise/digital_platforms_xl.jpg';
import mobile_applications_s from '../images/expertise/mobile_applications_s.jpg';
import mobile_applications_m from '../images/expertise/mobile_applications_m.jpg';
import mobile_applications_l from '../images/expertise/mobile_applications_l.jpg';
import mobile_applications_xl from '../images/expertise/mobile_applications_xl.jpg';
import creative_directions_s from '../images/expertise/creative_directions_s.jpg';
import creative_directions_m from '../images/expertise/creative_directions_m.jpg';
import creative_directions_l from '../images/expertise/creative_directions_l.jpg';
import creative_directions_xl from '../images/expertise/creative_directions_xl.jpg';
import branding_s from '../images/expertise/branding_s.jpg';
import branding_m from '../images/expertise/branding_m.jpg';
import branding_l from '../images/expertise/branding_l.jpg';
import branding_xl from '../images/expertise/branding_xl.jpg';

// EXPERTISE ICONS
// Branding icons
import notepad from '../images/expertise_icons/notepad.svg';
import palette from '../images/expertise_icons/palette.svg';
// Digital platforms
import javascript from '../images/expertise_icons/javascript.svg';
import magento from '../images/expertise_icons/magento.svg';
import php from '../images/expertise_icons/php.svg';
import sitecore from '../images/expertise_icons/sitecore.svg';
import sitefinity from '../images/expertise_icons/sitefinity.svg';
// Creative icons
import figma from '../images/expertise_icons/figma.svg';
import invision from '../images/expertise_icons/invision.svg';
import sketch from '../images/expertise_icons/sketch.svg';
import xd from '../images/expertise_icons/xd.svg';
import framer from '../images/expertise_icons/framer.svg';
// Mobile icons
import android from '../images/expertise_icons/android.svg';
import apple from '../images/expertise_icons/apple.svg';


/* TEAM PHOTOS */
import pavel_s from '../images/team/pavel_s.jpg';
import pavel_m from '../images/team/pavel_m.jpg';
import pavel_l from '../images/team/pavel_l.jpg';
import pavel_xl from '../images/team/pavel_xl.jpg';

import ilian_s from '../images/team/ilian_s.jpg';
import ilian_m from '../images/team/ilian_m.jpg';
import ilian_l from '../images/team/ilian_l.jpg';
import ilian_xl from '../images/team/ilian_xl.jpg';

import chopy_s from '../images/team/chopy_s.jpg';
import chopy_m from '../images/team/chopy_m.jpg';
import chopy_l from '../images/team/chopy_l.jpg';
import chopy_xl from '../images/team/chopy_xl.jpg';

import stoyan_s from '../images/team/stoyan_s.jpg';
import stoyan_m from '../images/team/stoyan_m.jpg';
import stoyan_l from '../images/team/stoyan_l.jpg';
import stoyan_xl from '../images/team/stoyan_xl.jpg';

import mari_s from '../images/team/mari_s.jpg';
import mari_m from '../images/team/mari_m.jpg';
import mari_l from '../images/team/mari_l.jpg';
import mari_xl from '../images/team/mari_xl.jpg';

import george_s from '../images/team/george_s.jpg';
import george_m from '../images/team/george_m.jpg';
import george_l from '../images/team/george_l.jpg';
import george_xl from '../images/team/george_xl.jpg';

import armin_s from '../images/team/armin_s.jpg';
import armin_m from '../images/team/armin_m.jpg';
import armin_l from '../images/team/armin_l.jpg';
import armin_xl from '../images/team/armin_xl.jpg';

import joost_s from '../images/team/joost_s.jpg';
import joost_m from '../images/team/joost_m.jpg';
import joost_l from '../images/team/joost_l.jpg';
import joost_xl from '../images/team/joost_xl.jpg';

import vili_s from '../images/team/vili_s.jpg';
import vili_m from '../images/team/vili_m.jpg';
import vili_l from '../images/team/vili_l.jpg';
import vili_xl from '../images/team/vili_xl.jpg';

import zori_s from '../images/team/zori_s.jpg';
import zori_m from '../images/team/zori_m.jpg';
import zori_l from '../images/team/zori_l.jpg';
import zori_xl from '../images/team/zori_xl.jpg';

import lora_s from '../images/team/lora_s.jpg';
import lora_m from '../images/team/lora_m.jpg';
import lora_l from '../images/team/lora_l.jpg';
import lora_xl from '../images/team/lora_xl.jpg';

import nat_s from '../images/team/nat_s.jpg';
import nat_m from '../images/team/nat_m.jpg';
import nat_l from '../images/team/nat_l.jpg';
import nat_xl from '../images/team/nat_xl.jpg';

import julian_s from '../images/team/julian_s.jpg';
import julian_m from '../images/team/julian_m.jpg';
import julian_l from '../images/team/julian_l.jpg';
import julian_xl from '../images/team/julian_xl.jpg';

import alex_s from '../images/team/alex_s.jpg';
import alex_m from '../images/team/alex_m.jpg';
import alex_l from '../images/team/alex_l.jpg';
import alex_xl from '../images/team/alex_xl.jpg';

import konstantin_s from '../images/team/konstantin_s.jpg';
import konstantin_m from '../images/team/konstantin_m.jpg';
import konstantin_l from '../images/team/konstantin_l.jpg';
import konstantin_xl from '../images/team/konstantin_xl.jpg';

import dani_s from '../images/team/dani_s.jpg';
import dani_m from '../images/team/dani_m.jpg';
import dani_l from '../images/team/dani_l.jpg';
import dani_xl from '../images/team/dani_xl.jpg';

import galin_s from '../images/team/galin_s.jpg';
import galin_m from '../images/team/galin_m.jpg';
import galin_l from '../images/team/galin_l.jpg';
import galin_xl from '../images/team/galin_xl.jpg';

import kiril_s from '../images/team/kiril_s.jpg';
import kiril_m from '../images/team/kiril_m.jpg';
import kiril_l from '../images/team/kiril_l.jpg';
import kiril_xl from '../images/team/kiril_xl.jpg';

import lyubo_s from '../images/team/lyubo_s.jpg';
import lyubo_m from '../images/team/lyubo_m.jpg';
import lyubo_l from '../images/team/lyubo_l.jpg';
import lyubo_xl from '../images/team/lyubo_xl.jpg';

import emi_s from '../images/team/emi_s.jpg';
import emi_m from '../images/team/emi_m.jpg';
import emi_l from '../images/team/emi_l.jpg';
import emi_xl from '../images/team/emi_xl.jpg';

import radovan_s from '../images/team/radovan_s.jpg';
import radovan_m from '../images/team/radovan_m.jpg';
import radovan_l from '../images/team/radovan_l.jpg';
import radovan_xl from '../images/team/radovan_xl.jpg';

import krisi_s from '../images/team/krisi_s.jpg';
import krisi_m from '../images/team/krisi_m.jpg';
import krisi_l from '../images/team/krisi_l.jpg';
import krisi_xl from '../images/team/krisi_xl.jpg';

import ivanv_s from '../images/team/ivanv_s.jpg';
import ivanv_m from '../images/team/ivanv_m.jpg';
import ivanv_l from '../images/team/ivanv_l.jpg';
import ivanv_xl from '../images/team/ivanv_xl.jpg';

import slav_s from '../images/team/slav_s.jpg';
import slav_m from '../images/team/slav_m.jpg';
import slav_l from '../images/team/slav_l.jpg';
import slav_xl from '../images/team/slav_xl.jpg';

import tsvetan_s from '../images/team/tsvetan_s.jpg';
import tsvetan_m from '../images/team/tsvetan_m.jpg';
import tsvetan_l from '../images/team/tsvetan_l.jpg';
import tsvetan_xl from '../images/team/tsvetan_xl.jpg';

import bojo_s from '../images/team/bojo_s.jpg';
import bojo_m from '../images/team/bojo_m.jpg';
import bojo_l from '../images/team/bojo_l.jpg';
import bojo_xl from '../images/team/bojo_xl.jpg';

import chocho_s from '../images/team/chocho_s.jpg';
import chocho_m from '../images/team/chocho_m.jpg';
import chocho_l from '../images/team/chocho_l.jpg';
import chocho_xl from '../images/team/chocho_xl.jpg';

import dobri_s from '../images/team/dobri_s.jpg';
import dobri_m from '../images/team/dobri_m.jpg';
import dobri_l from '../images/team/dobri_l.jpg';
import dobri_xl from '../images/team/dobri_xl.jpg';

import dimitard_s from '../images/team/dimitard_s.jpg';
import dimitard_m from '../images/team/dimitard_m.jpg';
import dimitard_l from '../images/team/dimitard_l.jpg';
import dimitard_xl from '../images/team/dimitard_xl.jpg';

import milena_s from '../images/team/milena_s.jpg';
import milena_m from '../images/team/milena_m.jpg';
import milena_l from '../images/team/milena_l.jpg';
import milena_xl from '../images/team/milena_xl.jpg';

// import valeto_s from '../images/team/valeto_s.jpg';
// import valeto_m from '../images/team/valeto_m.jpg';
// import valeto_l from '../images/team/valeto_l.jpg';
// import valeto_xl from '../images/team/valeto_xl.jpg';

import kristina_savova_s from '../images/team/kristina_savova_s.jpg';
import kristina_savova_m from '../images/team/kristina_savova_m.jpg';
import kristina_savova_l from '../images/team/kristina_savova_l.jpg';
import kristina_savova_xl from '../images/team/kristina_savova_xl.jpg';

import daniela_s from '../images/team/daniela_s.jpg';
import daniela_m from '../images/team/daniela_m.jpg';
import daniela_l from '../images/team/daniela_l.jpg';
import daniela_xl from '../images/team/daniela_xl.jpg';

import petar_s from '../images/team/petar_s.jpg';
import petar_m from '../images/team/petar_m.jpg';
import petar_l from '../images/team/petar_l.jpg';
import petar_xl from '../images/team/petar_xl.jpg';

import biljana_s from '../images/team/bilyana_s.jpg';
import biljana_m from '../images/team/bilyana_m.jpg';
import biljana_l from '../images/team/bilyana_l.jpg';
import biljana_xl from '../images/team/bilyana_xl.jpg';

import miroljub_s from '../images/team/mirolyub_s.jpg';
import miroljub_m from '../images/team/mirolyub_m.jpg';
import miroljub_l from '../images/team/mirolyub_l.jpg';
import miroljub_xl from '../images/team/mirolyub_xl.jpg';

import veronika_s from '../images/team/veronika_s.jpg';
import veronika_m from '../images/team/veronika_m.jpg';
import veronika_l from '../images/team/veronika_l.jpg';
import veronika_xl from '../images/team/veronika_xl.jpg';

import hristina_s from '../images/team/hristina_s.jpg';
import hristina_m from '../images/team/hristina_m.jpg';
import hristina_l from '../images/team/hristina_l.jpg';
import hristina_xl from '../images/team/hristina_xl.jpg';

import iva_s from '../images/team/iva_s.jpg';
import iva_m from '../images/team/iva_m.jpg';
import iva_l from '../images/team/iva_l.jpg';
import iva_xl from '../images/team/iva_xl.jpg';

import dejan_s from '../images/team/dejan_s.jpg';
import dejan_m from '../images/team/dejan_m.jpg';
import dejan_l from '../images/team/dejan_l.jpg';
import dejan_xl from '../images/team/dejan_xl.jpg';

import aleksandar_s from '../images/team/aleksandar_s.jpg';
import aleksandar_m from '../images/team/aleksandar_m.jpg';
import aleksandar_l from '../images/team/aleksandar_l.jpg';
import aleksandar_xl from '../images/team/aleksandar_xl.jpg';

import laza_s from '../images/team/laza_s.jpg';
import laza_m from '../images/team/laza_m.jpg';
import laza_l from '../images/team/laza_l.jpg';
import laza_xl from '../images/team/laza_xl.jpg';

import marko_s from '../images/team/marko_s.jpg';
import marko_m from '../images/team/marko_m.jpg';
import marko_l from '../images/team/marko_l.jpg';
import marko_xl from '../images/team/marko_xl.jpg';

// CLIENTS LOGOS
import ally from '../images/clients/ally.svg';
import bearsanddolphins from '../images/clients/bearsanddolphins.svg';
import boxuk from '../images/clients/boxuk.svg';
import brawezone from '../images/clients/brawezone.svg';
import cedar from '../images/clients/cedar.svg';
import cksk from '../images/clients/cksk.svg';
import claned from '../images/clients/claned.svg';
import dog from '../images/clients/dog.svg';
import finlauson from '../images/clients/finlauson.svg';
import getsafe from '../images/clients/getsafe.svg';
import icaw from '../images/clients/icaw.svg';
import isobar from '../images/clients/isobar.svg';
import kweak from '../images/clients/kweak.svg';
import liferheld from '../images/clients/liferheld.svg';
import lmb from '../images/clients/lmb.svg';
import madison_ally from '../images/clients/madison_ally.svg';
import mediaman from '../images/clients/mediaman.svg';
import mnstr from '../images/clients/mnstr.svg';
import mr_ms_smith from '../images/clients/mr_ms_smith.svg';
import msf from '../images/clients/msf.svg';
import oliver from '../images/clients/oliver.svg';
import ovo from '../images/clients/ovo.svg';
import siili from '../images/clients/siili.svg';
import supper from '../images/clients/supper.svg';
import unit from '../images/clients/unit.svg';
import viessman from '../images/clients/viessman.svg';
import zoomsquare from '../images/clients/zoomsquare.svg';
import interone from '../images/clients/interone.svg';
import precedent from '../images/clients/precedent.svg';
import massup from '../images/clients/massup.svg';
import ekahau from '../images/clients/ekahau.svg';
import ecx from '../images/clients/ecx.svg';
import fitogram from '../images/clients/fitogram.svg';
import urban_sports_club from '../images/clients/urban_sports_club.svg';


//References photos
import mathias_xl from '../images/references/mathias_xl.jpg';
import mathias_l from '../images/references/mathias_l.jpg';
import mathias_m from '../images/references/mathias_m.jpg';
import mathias_s from '../images/references/mathias_s.jpg';

import matti_xl from '../images/references/matti_xl.jpg';
import matti_l from '../images/references/matti_l.jpg';
import matti_m from '../images/references/matti_m.jpg';
import matti_s from '../images/references/matti_s.jpg';

import klaus_xl from '../images/references/klaus_xl.jpg';
import klaus_l from '../images/references/klaus_l.jpg';
import klaus_m from '../images/references/klaus_m.jpg';
import klaus_s from '../images/references/klaus_s.jpg';

import exposure_xl from '../images/references/exposure_xl.jpg';
import exposure_l from '../images/references/exposure_l.jpg';
import exposure_m from '../images/references/exposure_m.jpg';
import exposure_s from '../images/references/exposure_s.jpg';

import icaew_xl from '../images/references/icaew_xl.jpg';
import icaew_l from '../images/references/icaew_l.jpg';
import icaew_m from '../images/references/icaew_m.jpg';
import icaew_s from '../images/references/icaew_s.jpg';

import kweak_xl from '../images/references/kweak_xl.jpg';
import kweak_l from '../images/references/kweak_l.jpg';
import kweak_m from '../images/references/kweak_m.jpg';
import kweak_s from '../images/references/kweak_s.jpg';

import oliver_xl from '../images/references/oliver_xl.jpg';
import oliver_l from '../images/references/oliver_l.jpg';
import oliver_m from '../images/references/oliver_m.jpg';
import oliver_s from '../images/references/oliver_s.jpg';

import ovo_xl from '../images/references/ovo_xl.jpg';
import ovo_l from '../images/references/ovo_l.jpg';
import ovo_m from '../images/references/ovo_m.jpg';
import ovo_s from '../images/references/ovo_s.jpg';

import rika_xl from '../images/references/rika_xl.jpg';
import rika_l from '../images/references/rika_l.jpg';
import rika_m from '../images/references/rika_m.jpg';
import rika_s from '../images/references/rika_s.jpg';

import realitybytes_xl from '../images/references/realitybytes_xl.jpg';
import realitybytes_l from '../images/references/realitybytes_l.jpg';
import realitybytes_m from '../images/references/realitybytes_m.jpg';
import realitybytes_s from '../images/references/realitybytes_s.jpg';
import bmw from "../images/clients/bmw.svg";
import brita from "../images/clients/brita.svg";
import british_council from "../images/clients/british-council.svg";
import cnn from "../images/clients/cnn.svg";
import heineken from "../images/clients/heineken.svg";
import martini from "../images/clients/martini.svg";
import porsche from "../images/clients/porsche.svg";
import appnovation from "../images/clients/appnovation.svg";
import grohe from "../images/clients/grohe.svg";
import insta from "../images/clients/insta.svg";
import gasak from "../images/clients/gasag.svg";
import progress from "../images/clients/progress.svg";
import digitalist from "../images/clients/digitalist.svg";
import mirum from "../images/clients/mirum.svg";

export const slides = [
    {
        id: 1,
        config: {
            type: 'InfoSlide',
            className: 'about-main-slide',
            withMouseAnimation: true
        },
        "description": "pages.about.description",
        "type": PAGE_TYPE_EXTERNAL,
        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": mainImg_s
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": mainImg_m
            },
            "large": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": mainImg_l
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": mainImg_xl
            }
        }
    },
    [
        {
            id: 2,
            config: {
                type: 'InfoSlide',
                className: ['info-slide-bgk-scale', 'slide-about']
            },
            "title": "pages.about.expertiseTitle",
            "subtitle": "pages.about.expertiseSubtitle",
            "type": PAGE_TYPE_INTERNAL,
            "button": {
                "textKey": "pages.about.expertiseButtonText",
                "url": "/clients"
            },
            "background": {
                "small": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": expertiseImg_s
                },
                "medium": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": expertiseImg_m
                },
                "large": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": expertiseImg_l
                },
                "extraLarge": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": expertiseImg_xl
                }
            },
            dialogSlides: {
                sliderOptions: {
                    slidesPerView: 1,
                    mousewheel: true,
                    direction: 'vertical',
                    speed: 450,
                    effect: 'slide',
                    scrollbar: {
                        hide: false,
                        draggable: true,
                    }
                },
                config: {
                    mouseAnimationColor: '#000000'
                },
                slides: [
                    {
                        id: 1,
                        config: {
                            type: 'InfoSlide',
                            className: 'slide-expertise',
                            withMouseAnimation: true
                        },
                        "icons": [
                            javascript,
                            magento,
                            php,
                            sitecore,
                            sitefinity
                        ],
                        "title": "pages.about.dialogs.expertise.digitalPlatformsTitle",
                        "subtitle": "pages.about.dialogs.expertise.digitalPlatformsSubtitle",
                        "type": PAGE_TYPE_EXTERNAL,
                        "background": {
                            "small": {
                                "type": BACKGROUND_TYPE_IMAGE,
                                "url": digital_platforms_s
                            },
                            "medium": {
                                "type": BACKGROUND_TYPE_IMAGE,
                                "url": digital_platforms_m
                            },
                            "large": {
                                "type": BACKGROUND_TYPE_IMAGE,
                                "url": digital_platforms_l
                            },
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_IMAGE,
                                "url": digital_platforms_xl
                            }
                        }
                    },
                    {
                        id: 2,
                        config: {
                            type: 'InfoSlide',
                            className: 'slide-expertise--next-page'
                        },
                        "icons": [
                            apple,
                            android
                        ],
                        "title": "pages.about.dialogs.expertise.mobileApplicationsTitle",
                        "subtitle": "pages.about.dialogs.expertise.mobileApplicationsSubtitle",
                        "type": PAGE_TYPE_EXTERNAL,
                        "background": {
                            "small": {
                                "type": BACKGROUND_TYPE_IMAGE,
                                "url": mobile_applications_s
                            },
                            "medium": {
                                "type": BACKGROUND_TYPE_IMAGE,
                                "url": mobile_applications_m
                            },
                            "large": {
                                "type": BACKGROUND_TYPE_IMAGE,
                                "url": mobile_applications_l
                            },
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_IMAGE,
                                "url": mobile_applications_xl
                            }
                        }
                    },
                    {
                        id: 3,
                        config: {
                            type: 'InfoSlide',
                            className: 'slide-expertise--next-page'
                        },
                        "icons": [
                            figma,
                            sketch,
                            xd,
                            framer,
                            invision
                        ],
                        "title": "pages.about.dialogs.expertise.creativeDirectionsTitle",
                        "subtitle": "pages.about.dialogs.expertise.creativeDirectionsSubtitle",
                        "type": PAGE_TYPE_EXTERNAL,
                        "background": {
                            "small": {
                                "type": BACKGROUND_TYPE_IMAGE,
                                "url": creative_directions_s
                            },
                            "medium": {
                                "type": BACKGROUND_TYPE_IMAGE,
                                "url": creative_directions_m
                            },
                            "large": {
                                "type": BACKGROUND_TYPE_IMAGE,
                                "url": creative_directions_l
                            },
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_IMAGE,
                                "url": creative_directions_xl
                            }
                        }
                    },
                    {
                        id: 4,
                        config: {
                            type: 'InfoSlide',
                            className: 'slide-expertise--next-page'
                        },
                        "icons": [
                            notepad,
                            palette
                        ],
                        "title": "pages.about.dialogs.expertise.brandingTitle",
                        "subtitle": "pages.about.dialogs.expertise.brandingSubtitle",
                        "type": PAGE_TYPE_EXTERNAL,
                        "background": {
                            "small": {
                                "type": BACKGROUND_TYPE_IMAGE,
                                "url": branding_s
                            },
                            "medium": {
                                "type": BACKGROUND_TYPE_IMAGE,
                                "url": branding_m
                            },
                            "large": {
                                "type": BACKGROUND_TYPE_IMAGE,
                                "url": branding_l
                            },
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_IMAGE,
                                "url": branding_xl
                            }
                        }
                    }
                ]
            }
        },
        {
            id: 3,
            config: {
                type: 'InfoSlide',
                className: ['info-slide-bgk-scale', 'slide-about']
            },
            "title": "pages.about.teamTitle",
            "subtitle": "pages.about.teamSubtitle",
            "type": PAGE_TYPE_INTERNAL,
            "button": {
                "textKey": "pages.about.teamButtonText",
                "url": "/team"
            },
            "background": {
                "small": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": teamImg_s
                },
                "medium": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": teamImg_m
                },
                "large": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": teamImg_l
                },
                "extraLarge": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": teamImg_xl
                }
            },
            dialogSlides: {
                sliderOptions: {
                    slidesPerView: 1,
                    mousewheel: true,
                    direction: 'vertical',
                    speed: 450,
                    effect: 'slide',
                    scrollbar: {
                        hide: false,
                        draggable: true,
                    }
                },
                slides: [
                    [
                        {
                            id: 1,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left',
                                withLinkedIn: true
                            },
                            "title": "pages.about.dialogs.team.pavelName",
                            "subtitle": "pages.about.dialogs.team.pavelPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": pavel_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": pavel_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": pavel_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": pavel_xl
                                }
                            },
                            "linkedIn": "https://www.linkedin.com/in/pavelsimov"
                        },
                        {
                            id: 2,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left',
                                withLinkedIn: true
                            },
                            "title": "pages.about.dialogs.team.ilianName",
                            "subtitle": "pages.about.dialogs.team.ilianPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": ilian_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": ilian_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": ilian_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": ilian_xl
                                }
                            },
                            "linkedIn": "https://www.linkedin.com/in/iliankrustev"
                        }
                    ],
                    [
                        {
                            id: 3,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left',
                                withLinkedIn: true
                            },
                            "title": "pages.about.dialogs.team.chopyName",
                            "subtitle": "pages.about.dialogs.team.chopyPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": chopy_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": chopy_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": chopy_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": chopy_xl
                                }
                            },
                            "linkedIn": "https://www.linkedin.com/in/chopy/"
                        },
                        {
                            id: 4,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left',
                                withLinkedIn: true
                            },
                            "title": "pages.about.dialogs.team.stoyanName",
                            "subtitle": "pages.about.dialogs.team.stoyanPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": stoyan_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": stoyan_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": stoyan_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": stoyan_xl
                                }
                            },
                            "linkedIn": "https://www.linkedin.com/in/stoyan-yankov-b2525841"
                        }
                    ],
                    [
                        {
                            id: 5,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left',
                                withLinkedIn: true
                            },
                            "title": "pages.about.dialogs.team.mariName",
                            "subtitle": "pages.about.dialogs.team.mariPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": mari_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": mari_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": mari_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": mari_xl
                                }
                            },
                            "linkedIn": "https://www.linkedin.com/in/mariparviainen/"
                        },
                        {
                            id: 6,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left',
                                withLinkedIn: true
                            },
                            "title": "pages.about.dialogs.team.georgeName",
                            "subtitle": "pages.about.dialogs.team.georgePosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": george_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": george_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": george_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": george_xl
                                }
                            },
                            "linkedIn": "https://www.linkedin.com/in/georgievgeorge/"
                        }
                    ],
                    [
                        {
                            id: 7,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left',
                                withLinkedIn: true
                            },
                            "title": "pages.about.dialogs.team.arminName",
                            "subtitle": "pages.about.dialogs.team.arminPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": armin_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": armin_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": armin_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": armin_xl
                                }
                            },
                            "linkedIn": "https://www.linkedin.com/in/armin-at-droxic/"
                        },
                        {
                            id: 8,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left',
                                withLinkedIn: true
                            },
                            "title": "pages.about.dialogs.team.joostName",
                            "subtitle": "pages.about.dialogs.team.joostPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": joost_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": joost_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": joost_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": joost_xl
                                }
                            },
                            "linkedIn": "https://www.linkedin.com/in/jscholte/"
                        }
                    ],
                    [
                        {
                            id: 9,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left',
                                withLinkedIn: true
                            },
                            "title": "pages.about.dialogs.team.viliName",
                            "subtitle": "pages.about.dialogs.team.viliPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": vili_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": vili_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": vili_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": vili_xl
                                }
                            },
                            "linkedIn": "https://www.linkedin.com/in/violetagyoreva/"
                        },
                        {
                            id: 10,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left',
                                withLinkedIn: true
                            },
                            "title": "pages.about.dialogs.team.zoriName",
                            "subtitle": "pages.about.dialogs.team.zoriPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": zori_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": zori_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": zori_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": zori_xl
                                }
                            },
                            "linkedIn": "https://www.linkedin.com/in/zornitsa-konstantinova-0a6a67a1/"
                        }
                    ],
                    [
                        {
                            id: 11,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left',
                                withLinkedIn: true
                            },
                            "title": "pages.about.dialogs.team.danielaName",
                            "subtitle": "pages.about.dialogs.team.danielaPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": daniela_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": daniela_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": daniela_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": daniela_xl
                                }
                            },
                            "linkedIn": "https://www.linkedin.com/in/daniela-atanasova-5295a865/"
                        },
                        {
                            id: 12,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left',
                                withLinkedIn: true
                            },
                            "title": "pages.about.dialogs.team.natName",
                            "subtitle": "pages.about.dialogs.team.natPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": nat_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": nat_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": nat_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": nat_xl
                                }
                            },
                            "linkedIn": "https://www.linkedin.com/in/nataliya-koseva-0014a2147/"
                        }
                    ],
                    [
                        {
                            id: 13,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left',
                                withLinkedIn: true
                            },
                            "title": "pages.about.dialogs.team.loraName",
                            "subtitle": "pages.about.dialogs.team.loraPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": lora_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": lora_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": lora_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": lora_xl
                                }
                            },
                            "linkedIn": "https://www.linkedin.com/in/lora-toteva-3a15aa63/"
                        },
                        {
                            id: 14,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left',
                                withLinkedIn: true
                            },
                            "title": "pages.about.dialogs.team.veronikaName",
                            "subtitle": "pages.about.dialogs.team.veronikaPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": veronika_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": veronika_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": veronika_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": veronika_xl
                                }
                            },
                            "linkedIn": "https://www.linkedin.com/in/veronika-varbanova-26a201176/"
                        }
                    ],
                    [
                        {
                            id: 15,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left',
                                withLinkedIn: true
                            },
                            "title": "pages.about.dialogs.team.ivaName",
                            "subtitle": "pages.about.dialogs.team.ivaPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": iva_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": iva_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": iva_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": iva_xl
                                }
                            },
                            "linkedIn": "https://www.linkedin.com/in/iva-popova/"
                        },
                        {
                            id: 16,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left',
                                withLinkedIn: true
                            },
                            "title": "pages.about.dialogs.team.hristinaName",
                            "subtitle": "pages.about.dialogs.team.hristinaPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": hristina_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": hristina_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": hristina_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": hristina_xl
                                }
                            },
                            "linkedIn": "https://www.linkedin.com/in/hristinavladkovadimitrova/"
                        },
                    ],
                    [
                        {
                            id: 17,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left'
                            },
                            "title": "pages.about.dialogs.team.julianName",
                            "subtitle": "pages.about.dialogs.team.julianPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": julian_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": julian_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": julian_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": julian_xl
                                }
                            }
                        },
                        {
                            id: 18,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left'
                            },
                            "title": "pages.about.dialogs.team.alexName",
                            "subtitle": "pages.about.dialogs.team.alexPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": alex_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": alex_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": alex_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": alex_xl
                                }
                            }
                        },
                    ],
                    [
                        {
                            id: 19,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left'
                            },
                            "title": "pages.about.dialogs.team.konstantinName",
                            "subtitle": "pages.about.dialogs.team.konstantinPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": konstantin_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": konstantin_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": konstantin_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": konstantin_xl
                                }
                            }
                        },
                        {
                            id: 20,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left'
                            },
                            "title": "pages.about.dialogs.team.daniName",
                            "subtitle": "pages.about.dialogs.team.daniPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": dani_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": dani_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": dani_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": dani_xl
                                }
                            }
                        }
                    ],
                    [
                        {
                            id: 21,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left'
                            },
                            "title": "pages.about.dialogs.team.kirilName",
                            "subtitle": "pages.about.dialogs.team.kirilPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": kiril_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": kiril_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": kiril_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": kiril_xl
                                }
                            }
                        },
                        {
                            id: 22,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left'
                            },
                            "title": "pages.about.dialogs.team.lyuboName",
                            "subtitle": "pages.about.dialogs.team.lyuboPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": lyubo_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": lyubo_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": lyubo_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": lyubo_xl
                                }
                            }
                        }
                    ],
                    [
                        {
                            id: 23,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left'
                            },
                            "title": "pages.about.dialogs.team.galinName",
                            "subtitle": "pages.about.dialogs.team.galinPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": galin_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": galin_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": galin_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": galin_xl
                                }
                            }
                        },
                        {
                            id: 24,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left'
                            },
                            "title": "pages.about.dialogs.team.emiName",
                            "subtitle": "pages.about.dialogs.team.emiPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": emi_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": emi_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": emi_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": emi_xl
                                }
                            }
                        },
                    ],
                    [
                        {
                            id: 26,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left'
                            },
                            "title": "pages.about.dialogs.team.ivanvName",
                            "subtitle": "pages.about.dialogs.team.ivanvPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": ivanv_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": ivanv_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": ivanv_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": ivanv_xl
                                }
                            }
                        },
                        {
                            id: 29,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left'
                            },
                            "title": "pages.about.dialogs.team.krisiName",
                            "subtitle": "pages.about.dialogs.team.krisiPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": krisi_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": krisi_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": krisi_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": krisi_xl
                                }
                            }
                        }
                    ],
                    [
                        {
                            id: 27,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left'
                            },
                            "title": "pages.about.dialogs.team.radovanName",
                            "subtitle": "pages.about.dialogs.team.radovanPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": radovan_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": radovan_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": radovan_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": radovan_xl
                                }
                            }
                        },
                        {
                            id: 28,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left'
                            },
                            "title": "pages.about.dialogs.team.aleksandarName",
                            "subtitle": "pages.about.dialogs.team.aleksandarPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": aleksandar_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": aleksandar_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": aleksandar_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": aleksandar_xl
                                }
                            }
                        },
                    ],
                    [
                        {
                            id: 30,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left'
                            },
                            "title": "pages.about.dialogs.team.slavName",
                            "subtitle": "pages.about.dialogs.team.slavPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": slav_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": slav_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": slav_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": slav_xl
                                }
                            }
                        },
                        {
                            id: 31,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left'
                            },
                            "title": "pages.about.dialogs.team.lazaName",
                            "subtitle": "pages.about.dialogs.team.lazaPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": laza_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": laza_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": laza_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": laza_xl
                                }
                            }
                        }
                    ],
                    [
                        {
                            id: 34,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left'
                            },
                            "title": "pages.about.dialogs.team.tsvetanName",
                            "subtitle": "pages.about.dialogs.team.tsvetanPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": tsvetan_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": tsvetan_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": tsvetan_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": tsvetan_xl
                                }
                            }
                        },
                        {
                            id: 33,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left'
                            },
                            "title": "pages.about.dialogs.team.dimitardName",
                            "subtitle": "pages.about.dialogs.team.dimitardPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": dimitard_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": dimitard_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": dimitard_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": dimitard_xl
                                }
                            }
                        },
                    ],
                    [
                        {
                            id: 32,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left'
                            },
                            "title": "pages.about.dialogs.team.markoName",
                            "subtitle": "pages.about.dialogs.team.markoPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": marko_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": marko_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": marko_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": marko_xl
                                }
                            }
                        },
                        {
                            id: 35,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left'
                            },
                            "title": "pages.about.dialogs.team.dobriName",
                            "subtitle": "pages.about.dialogs.team.dobriPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": dobri_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": dobri_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": dobri_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": dobri_xl
                                }
                            }
                        }
                    ],
                    [
                        {
                            id: 36,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left'
                            },
                            "title": "pages.about.dialogs.team.biljanaName",
                            "subtitle": "pages.about.dialogs.team.biljanaPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": biljana_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": biljana_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": biljana_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": biljana_xl
                                }
                            }
                        },
                        {
                            id: 37,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left'
                            },
                            "title": "pages.about.dialogs.team.petarName",
                            "subtitle": "pages.about.dialogs.team.petarPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": petar_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": petar_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": petar_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": petar_xl
                                }
                            }
                        }
                    ],
                    [
                        {
                            id: 38,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left'
                            },
                            "title": "pages.about.dialogs.team.miroljubName",
                            "subtitle": "pages.about.dialogs.team.miroljubPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": miroljub_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": miroljub_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": miroljub_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": miroljub_xl
                                }
                            }
                        },
                        {
                            id: 39,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left'
                            },
                            "title": "pages.about.dialogs.team.chochoName",
                            "subtitle": "pages.about.dialogs.team.chochoPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": chocho_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": chocho_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": chocho_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": chocho_xl
                                }
                            }
                        }
                    ],
                    [
                        {
                            id: 40,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left'
                            },
                            "title": "pages.about.dialogs.team.dejanName",
                            "subtitle": "pages.about.dialogs.team.dejanPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": dejan_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": dejan_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": dejan_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": dejan_xl
                                }
                            }
                        },
                        {
                            id: 41,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left'
                            },
                            "title": "pages.about.dialogs.team.bojoName",
                            "subtitle": "pages.about.dialogs.team.bojoPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": bojo_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": bojo_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": bojo_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": bojo_xl
                                }
                            }
                        }
                    ],
                    [
                        {
                            id: 42,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left'
                            },
                            "title": "pages.about.dialogs.team.kristinaSavovaName",
                            "subtitle": "pages.about.dialogs.team.kristinaSavovaPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": kristina_savova_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": kristina_savova_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": kristina_savova_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": kristina_savova_xl
                                }
                            }
                        },
                        {
                            id: 43,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-team-bgk-scale', 'slide-team'],
                                horizontalAlignment: 'left'
                            },
                            "title": "pages.about.dialogs.team.milenaName",
                            "subtitle": "pages.about.dialogs.team.milenaPosition",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": milena_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": milena_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": milena_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": milena_xl
                                }
                            }
                        }
                    ]
                ]
            }
        }
    ],
    [
        {
            id: 4,
            config: {
                type: 'InfoSlide',
                className: ['info-slide-bgk-scale', 'slide-about']
            },
            "title": "pages.about.clientsTitle",
            "subtitle": "pages.about.clientsSubtitle",
            "type": PAGE_TYPE_INTERNAL,
            "button": {
                "textKey": "pages.about.clientsButtonText",
                "url": "/clients"
            },
            "background": {
                "small": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": clientsImg_s
                },
                "medium": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": clientsImg_m
                },
                "large": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": clientsImg_l
                },
                "extraLarge": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": clientsImg_xl
                }
            },
            dialogSlides: {
                sliderOptions: {
                    slidesPerView: 1,
                    mousewheel: true,
                    direction: 'vertical',
                    speed: 450,
                    effect: 'slide',
                    scrollbar: {
                        hide: false,
                        draggable: true,
                    }
                },
                config: {
                    mouseAnimationColor: '#000000'
                },
                slides: [
                    [
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: siili,
                            clientName: "siili",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: liferheld,
                            clientName: "liferheld",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: unit,
                            clientName: "unit",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: bmw,
                            clientName: "bmw",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        }
                    ],
                    [
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: grohe,
                            clientName: "grohe",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: insta,
                            clientName: "insta",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: gasak,
                            clientName: "gasak",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: interone,
                            clientName: "interone",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                    ],
                    [
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: ovo,
                            clientName: "ovo",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: massup,
                            clientName: "massup",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: mediaman,
                            clientName: "mediaman",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: ekahau,
                            clientName: "ekahau",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        }
                    ],
                    [
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: claned,
                            clientName: "claned",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: ecx,
                            clientName: "ecx",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: british_council,
                            clientName: "british_council",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide',
                                buttonColor: 'HORAY, IN inner slider #1'
                            },
                            clientLogo: ally,
                            clientName: "ally",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        }
                    ],
                    [
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: bearsanddolphins,
                            clientName: "bearsanddolphins",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: boxuk,
                            clientName: "boxuk",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: cnn,
                            clientName: "cnn",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: brawezone,
                            clientName: "brawezone",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        }
                    ],
                    [
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: cedar,
                            clientName: "cedar",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: cksk,
                            clientName: "cksk",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: heineken,
                            clientName: "heineken",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: zoomsquare,
                            clientName: "zoomsquare",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        }
                    ],
                    [
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: dog,
                            clientName: "dog",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: viessman,
                            clientName: "viessman",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: martini,
                            clientName: "martini",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: finlauson,
                            clientName: "finlauson",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        }
                    ],
                    [
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: getsafe,
                            clientName: "getsafe",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: icaw,
                            clientName: "icaw",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: porsche,
                            clientName: "porsche",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: isobar,
                            clientName: "isobar",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        }
                    ],
                    [
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: kweak,
                            clientName: "kweak",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: lmb,
                            clientName: "lmb",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: madison_ally,
                            clientName: "madison_ally",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: brita,
                            clientName: "brita",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        }
                    ],
                    [
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: mnstr,
                            clientName: "mnstr",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: mr_ms_smith,
                            clientName: "mr_ms_smith",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: msf,
                            clientName: "msf",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide',
                                buttonColor: 'HORAY, IN inner slider #2'
                            },
                            clientLogo: oliver,
                            clientName: "oliver",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        }
                    ],
                    [
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: appnovation,
                            clientName: "appnovation",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: supper,
                            clientName: "supper",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: precedent,
                            clientName: "precedent",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: fitogram,
                            clientName: "fitogram",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        }
                    ],
                    [
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: urban_sports_club,
                            clientName: "urban_sports_club",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: progress,
                            clientName: "progress",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: digitalist,
                            clientName: "digitalist",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            config: {
                                type: 'ClientSlide'
                            },
                            clientLogo: mirum,
                            clientName: "mirum",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        }
                    ]
                ],
                responsive: {
                    small: {
                        sliderOptions: {
                            slidesPerView: 1,
                            mousewheel: true,
                            direction: 'vertical',
                            speed: 450,
                            effect: 'slide',
                            scrollbar: {
                                hide: false,
                                draggable: true,
                            }
                        },
                        slides: [
                            [
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: siili,
                                    clientName: "siili",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: liferheld,
                                    clientName: "liferheld",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: unit,
                                    clientName: "unit",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: bmw,
                                    clientName: "bmw",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                }
                            ],
                            [
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: grohe,
                                    clientName: "grohe",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: insta,
                                    clientName: "insta",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: gasak,
                                    clientName: "gasak",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: interone,
                                    clientName: "interone",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                }
                            ],
                            [
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: ovo,
                                    clientName: "ovo",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: massup,
                                    clientName: "massup",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: mediaman,
                                    clientName: "mediaman",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: ekahau,
                                    clientName: "ekahau",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                }
                            ],
                            [
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: claned,
                                    clientName: "claned",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: ecx,
                                    clientName: "ecx",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: british_council,
                                    clientName: "british_council",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide',
                                        buttonColor: 'HORAY, IN inner slider #1'
                                    },
                                    clientLogo: ally,
                                    clientName: "ally",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                }
                            ],
                            [
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: bearsanddolphins,
                                    clientName: "bearsanddolphins",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: boxuk,
                                    clientName: "boxuk",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: cnn,
                                    clientName: "cnn",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: brawezone,
                                    clientName: "brawezone",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                }
                            ],
                            [
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: cedar,
                                    clientName: "cedar",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: cksk,
                                    clientName: "cksk",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: heineken,
                                    clientName: "heineken",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: zoomsquare,
                                    clientName: "zoomsquare",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                }
                            ],
                            [
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: dog,
                                    clientName: "dog",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: viessman,
                                    clientName: "viessman",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: martini,
                                    clientName: "martini",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: finlauson,
                                    clientName: "finlauson",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                }
                            ],
                            [
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: getsafe,
                                    clientName: "getsafe",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: icaw,
                                    clientName: "icaw",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: porsche,
                                    clientName: "porsche",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: isobar,
                                    clientName: "isobar",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                }
                            ],
                            [
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: kweak,
                                    clientName: "kweak",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: lmb,
                                    clientName: "lmb",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: madison_ally,
                                    clientName: "madison_ally",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: brita,
                                    clientName: "brita",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                }
                            ],
                            [
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: mnstr,
                                    clientName: "mnstr",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: mr_ms_smith,
                                    clientName: "mr_ms_smith",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: msf,
                                    clientName: "msf",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide',
                                        buttonColor: 'HORAY, IN inner slider #2'
                                    },
                                    clientLogo: oliver,
                                    clientName: "oliver",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                }
                            ],
                            [
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: appnovation,
                                    clientName: "appnovation",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: supper,
                                    clientName: "supper",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: precedent,
                                    clientName: "precedent",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: fitogram,
                                    clientName: "fitogram",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                }
                            ],
                            [
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: urban_sports_club,
                                    clientName: "urban_sports_club",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: progress,
                                    clientName: "progress",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: digitalist,
                                    clientName: "digitalist",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: mirum,
                                    clientName: "mirum",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                }
                            ]
                        ]
                    },
                    medium: {
                        sliderOptions: {
                            slidesPerView: 1,
                            mousewheel: true,
                            direction: 'vertical',
                            speed: 450,
                            effect: 'slide',
                            scrollbar: {
                                hide: false,
                                draggable: true,
                            }
                        },
                        slides: [
                            [
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: siili,
                                    clientName: "siili",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: liferheld,
                                    clientName: "liferheld",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: unit,
                                    clientName: "unit",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: bmw,
                                    clientName: "bmw",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: grohe,
                                    clientName: "grohe",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: insta,
                                    clientName: "insta",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                }
                            ],
                            [
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: gasak,
                                    clientName: "gasak",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: interone,
                                    clientName: "interone",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: ovo,
                                    clientName: "ovo",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: massup,
                                    clientName: "massup",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: mediaman,
                                    clientName: "mediaman",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: ekahau,
                                    clientName: "ekahau",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                }
                            ],
                            [
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: claned,
                                    clientName: "claned",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: ecx,
                                    clientName: "ecx",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: british_council,
                                    clientName: "british_council",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide',
                                        buttonColor: 'HORAY, IN inner slider #1'
                                    },
                                    clientLogo: ally,
                                    clientName: "ally",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: bearsanddolphins,
                                    clientName: "bearsanddolphins",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: boxuk,
                                    clientName: "boxuk",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                }
                            ],
                            [
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: cnn,
                                    clientName: "cnn",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: brawezone,
                                    clientName: "brawezone",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: cedar,
                                    clientName: "cedar",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: cksk,
                                    clientName: "cksk",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: heineken,
                                    clientName: "heineken",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: zoomsquare,
                                    clientName: "zoomsquare",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                }
                            ],
                            [
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: dog,
                                    clientName: "dog",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: viessman,
                                    clientName: "viessman",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: martini,
                                    clientName: "martini",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: finlauson,
                                    clientName: "finlauson",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: getsafe,
                                    clientName: "getsafe",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: icaw,
                                    clientName: "icaw",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                }
                            ],
                            [
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: porsche,
                                    clientName: "porsche",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: isobar,
                                    clientName: "isobar",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: kweak,
                                    clientName: "kweak",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: lmb,
                                    clientName: "lmb",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: madison_ally,
                                    clientName: "madison_ally",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: brita,
                                    clientName: "brita",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                }
                            ],
                            [
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: mnstr,
                                    clientName: "mnstr",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: mr_ms_smith,
                                    clientName: "mr_ms_smith",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: msf,
                                    clientName: "msf",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide',
                                        buttonColor: 'HORAY, IN inner slider #2'
                                    },
                                    clientLogo: oliver,
                                    clientName: "oliver",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: appnovation,
                                    clientName: "appnovation",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: supper,
                                    clientName: "supper",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                }
                            ],
                            [
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: precedent,
                                    clientName: "precedent",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: fitogram,
                                    clientName: "fitogram",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: urban_sports_club,
                                    clientName: "urban_sports_club",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: progress,
                                    clientName: "progress",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: digitalist,
                                    clientName: "digitalist",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: mirum,
                                    clientName: "mirum",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                }
                            ]
                        ]
                    },
                    large: {
                        sliderOptions: {
                            slidesPerView: 1,
                            mousewheel: true,
                            direction: 'vertical',
                            speed: 450,
                            effect: 'slide',
                            scrollbar: {
                                hide: false,
                                draggable: true,
                            }
                        },
                        slides: [
                            [
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: siili,
                                    clientName: "siili",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: liferheld,
                                    clientName: "liferheld",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: unit,
                                    clientName: "unit",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: bmw,
                                    clientName: "bmw",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: grohe,
                                    clientName: "grohe",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: insta,
                                    clientName: "insta",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: gasak,
                                    clientName: "gasak",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: interone,
                                    clientName: "interone",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: ovo,
                                    clientName: "ovo",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                }
                            ],
                            [
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: massup,
                                    clientName: "massup",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: mediaman,
                                    clientName: "mediaman",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: ekahau,
                                    clientName: "ekahau",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: claned,
                                    clientName: "claned",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: ecx,
                                    clientName: "ecx",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: british_council,
                                    clientName: "british_council",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide',
                                        buttonColor: 'HORAY, IN inner slider #1'
                                    },
                                    clientLogo: ally,
                                    clientName: "ally",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: bearsanddolphins,
                                    clientName: "bearsanddolphins",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: boxuk,
                                    clientName: "boxuk",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                }
                            ],
                            [
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: cnn,
                                    clientName: "cnn",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: brawezone,
                                    clientName: "brawezone",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: cedar,
                                    clientName: "cedar",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: cksk,
                                    clientName: "cksk",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: heineken,
                                    clientName: "heineken",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: zoomsquare,
                                    clientName: "zoomsquare",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: dog,
                                    clientName: "dog",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: viessman,
                                    clientName: "viessman",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: martini,
                                    clientName: "martini",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                }
                            ],
                            [
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: finlauson,
                                    clientName: "finlauson",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: getsafe,
                                    clientName: "getsafe",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: icaw,
                                    clientName: "icaw",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: porsche,
                                    clientName: "porsche",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: isobar,
                                    clientName: "isobar",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: kweak,
                                    clientName: "kweak",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: lmb,
                                    clientName: "lmb",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: madison_ally,
                                    clientName: "madison_ally",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: brita,
                                    clientName: "brita",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                }
                            ],
                            [
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: mnstr,
                                    clientName: "mnstr",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: mr_ms_smith,
                                    clientName: "mr_ms_smith",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: msf,
                                    clientName: "msf",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide',
                                        buttonColor: 'HORAY, IN inner slider #2'
                                    },
                                    clientLogo: oliver,
                                    clientName: "oliver",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: appnovation,
                                    clientName: "appnovation",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: supper,
                                    clientName: "supper",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: precedent,
                                    clientName: "precedent",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: fitogram,
                                    clientName: "fitogram",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: urban_sports_club,
                                    clientName: "urban_sports_club",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                }
                            ],
                            [
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: progress,
                                    clientName: "progress",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: digitalist,
                                    clientName: "digitalist",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                },
                                {
                                    config: {
                                        type: 'ClientSlide'
                                    },
                                    clientLogo: mirum,
                                    clientName: "mirum",
                                    "background": {
                                        "extraLarge": {
                                            "type": BACKGROUND_TYPE_SOLID_COLOR,
                                            "url": "#FFFFFF"
                                        }
                                    }
                                }
                            ]
                        ]
                    }
                }
            }
        },
        {
            id: 5,
            config: {
                type: 'InfoSlide',
                className: ['info-slide-bgk-scale', 'slide-about']
            },
            "title": "pages.about.referencesTitle",
            "subtitle": "pages.about.referencesSubtitle",
            "type": PAGE_TYPE_INTERNAL,
            "button": {
                "textKey": "pages.about.referencesButtonText",
                "url": "/expertise"
            },
            "background": {
                "small": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": referencesImg_s
                },
                "medium": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": referencesImg_m
                },
                "large": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": referencesImg_l
                },
                "extraLarge": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": referencesImg_xl
                }
            },
            dialogSlides: {
                sliderOptions: {
                    slidesPerView: 1,
                    mousewheel: true,
                    direction: 'vertical',
                    speed: 450,
                    effect: 'slide',
                    scrollbar: {
                        hide: false,
                        draggable: true,
                    }
                },
                config: {
                    mouseAnimationColor: '#000000'
                },
                slides: [
                    [
                        {
                            id: 1,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order',
                                verticalAlignment: 'top',
                                bigSlideItem: true
                            },
                            "longDescription": "pages.about.dialogs.references.lieferheldDescription",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            id: 2,
                            config: {
                                type: 'InfoSlide',
                                className: ['info-slide-reversed-order', 'first-reference'],
                                horizontalAlignment: 'left',
                                renderSmallerFontSize: true,
                                smallSlideItem: true
                            },
                            "title": "pages.about.dialogs.references.lieferheldName",
                            "subtitle": "pages.about.dialogs.references.lieferheldPosition",
                            "companyName": "pages.about.dialogs.references.lieferheldCompany",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": mathias_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": mathias_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": mathias_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": mathias_xl
                                }
                            }
                        }
                    ],
                    [
                        {
                            id: 3,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order',
                                verticalAlignment: 'top',
                                bigSlideItem: true
                            },
                            "longDescription": "pages.about.dialogs.references.siiliDescription",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            id: 4,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order',
                                horizontalAlignment: 'left',
                                renderSmallerFontSize: true,
                                smallSlideItem: true
                            },
                            "title": "pages.about.dialogs.references.siiliName",
                            "subtitle": "pages.about.dialogs.references.siiliPosition",
                            "companyName": "pages.about.dialogs.references.siiliCompany",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": matti_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": matti_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": matti_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": matti_xl
                                }
                            }
                        }
                    ],
                    [
                        {
                            id: 5,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order',
                                verticalAlignment: 'top',
                                bigSlideItem: true
                            },
                            "longDescription": "pages.about.dialogs.references.initDescription",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            id: 6,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order',
                                horizontalAlignment: 'left',
                                renderSmallerFontSize: true,
                                smallSlideItem: true
                            },
                            "title": "pages.about.dialogs.references.initName",
                            "subtitle": "pages.about.dialogs.references.initPosition",
                            "companyName": "pages.about.dialogs.references.initCompany",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": klaus_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": klaus_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": klaus_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": klaus_xl
                                }
                            }
                        }
                    ],
                    [
                        {
                            id: 7,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order',
                                verticalAlignment: 'top',
                                bigSlideItem: true
                            },
                            "longDescription": "pages.about.dialogs.references.realityBytesDescription",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            id: 8,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order',
                                horizontalAlignment: 'left',
                                renderSmallerFontSize: true,
                                smallSlideItem: true
                            },
                            "title": "pages.about.dialogs.references.realityBytesName",
                            "subtitle": "pages.about.dialogs.references.realityBytesPosition",
                            "companyName": "pages.about.dialogs.references.realityBytesCompany",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": realitybytes_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": realitybytes_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": realitybytes_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": realitybytes_xl
                                }
                            }
                        }
                    ],
                    [
                        {
                            id: 9,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order',
                                verticalAlignment: 'top',
                                bigSlideItem: true
                            },
                            "longDescription": "pages.about.dialogs.references.oliverUkDescription",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            id: 10,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order',
                                horizontalAlignment: 'left',
                                renderSmallerFontSize: true,
                                smallSlideItem: true
                            },
                            "title": "pages.about.dialogs.references.oliverUkName",
                            "subtitle": "pages.about.dialogs.references.oliverUkPosition",
                            "companyName": "pages.about.dialogs.references.oliverUkCompany",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": oliver_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": oliver_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": oliver_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": oliver_xl
                                }
                            }
                        }
                    ],
                    [
                        {
                            id: 11,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order',
                                verticalAlignment: 'top',
                                bigSlideItem: true
                            },
                            "longDescription": "pages.about.dialogs.references.kweakDescription",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            id: 12,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order',
                                horizontalAlignment: 'left',
                                renderSmallerFontSize: true,
                                smallSlideItem: true
                            },
                            "title": "pages.about.dialogs.references.kweakName",
                            "subtitle": "pages.about.dialogs.references.kweakPosition",
                            "companyName": "pages.about.dialogs.references.kweakCompany",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": kweak_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": kweak_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": kweak_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": kweak_xl
                                }
                            }
                        }
                    ],
                    [
                        {
                            id: 13,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order',
                                verticalAlignment: 'top',
                                bigSlideItem: true
                            },
                            "longDescription": "pages.about.dialogs.references.exposureDescription",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            id: 14,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order',
                                horizontalAlignment: 'left',
                                renderSmallerFontSize: true,
                                smallSlideItem: true
                            },
                            "title": "pages.about.dialogs.references.exposureName",
                            "subtitle": "pages.about.dialogs.references.exposurePosition",
                            "companyName": "pages.about.dialogs.references.exposureCompany",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": exposure_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": exposure_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": exposure_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": exposure_xl
                                }
                            }
                        }
                    ],
                    [
                        {
                            id: 15,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order',
                                verticalAlignment: 'top',
                                bigSlideItem: true
                            },
                            "longDescription": "pages.about.dialogs.references.icaewDescription",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            id: 16,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order',
                                horizontalAlignment: 'left',
                                renderSmallerFontSize: true,
                                smallSlideItem: true
                            },
                            "title": "pages.about.dialogs.references.icaewName",
                            "subtitle": "pages.about.dialogs.references.icaewPosition",
                            "companyName": "pages.about.dialogs.references.icaewCompany",
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": icaew_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": icaew_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": icaew_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": icaew_xl
                                }
                            }
                        }
                    ]
                ]
            }
        }
    ]
];
