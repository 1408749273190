import React, { useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { gsap, Sine } from 'gsap';
import { resolveUrlPath } from '../../../utils/routerUtility';
import styles from './MenuFooter.module.css';

const MenuFooter = props => {
    const footerRef = useRef(null);

    useEffect(() => {
        const { isOpened } = props;
        const delay = isOpened ? .35 : 0.1;
        const opacity = isOpened ? 1 : 0;
        const paddingLeft = isOpened ? 0 : 50;
        gsap.to(footerRef.current, .2, {
            delay,
            opacity,
            paddingLeft,
            ease: Sine.easeOut
        });
    }, [props.isOpened]);

    return (
        <footer ref={footerRef} className={styles.container}>
            <span className={styles.followUs}><FormattedMessage id={'menu.followUs'} /></span>

            <div className={styles.socialLinksContainer}>
                <a href='https://www.linkedin.com/company/5062305/' target="_blank">
                    <svg
                        id='linkedinIcon'
                        xmlns='http://www.w3.org/2000/svg'
                        width='38.48'
                        height='36.54'
                        viewBox='0 0 38.48 36.54'
                    >
                        <title>LinkedIn</title>
                        <g id='Shape'>
                            <path
                                id='path2_fill'
                                data-name='path2 fill'
                                d='M36.48,41.54V27.4c0-7.57-4.07-11.1-9.5-11.1a8.19,8.19,0,0,0-7.43,4.07V16.88H11.3c.11,2.31,0,24.65,0,24.65h8.25V27.77a5.55,5.55,0,0,1,.27-2,4.51,4.51,0,0,1,4.23-3c3,0,4.18,2.26,4.18,5.57V41.54h8.25Zm-33.87-28c2.88,0,4.67-1.89,4.67-4.26A4.27,4.27,0,0,0,2.67,5C-.15,5-2,6.84-2,9.26a4.26,4.26,0,0,0,4.56,4.26Zm4.13,28V16.88H-1.51V41.54Z'
                                transform='translate(2 -5)'
                                fill='#fff'
                            />
                        </g>
                    </svg>
                </a>

                <a href='https://www.facebook.com/droxicdotcom' target="_blank">
                    <svg
                        id='facebookIcon'
                        data-name='Layer 1'
                        xmlns='http://www.w3.org/2000/svg'
                        width='17.93'
                        height='36.1'
                        viewBox='0 0 17.93 36.1'
                    >
                        <title>Facebook</title>
                        <path
                            id='path0_fill'
                            data-name='path0 fill'
                            d='M12.14,42.11h8V24.06h5.3l.7-6.22h-6V14.72c0-1.62.16-2.49,2.65-2.49h3.32V6H20.76c-6.37,0-8.62,3-8.62,8.09v3.74h-4v6.22h4V42.11Z'
                            transform='translate(-8.17 -6.01)'
                            fill='#fff'
                        />
                    </svg>
                </a>

                <a href='https://twitter.com/droxicdotcom' target="_blank">
                    <svg
                        id='twitterIcon'
                        data-name='Layer 1'
                        xmlns='http://www.w3.org/2000/svg'
                        width='38.48'
                        height='32.14'
                        viewBox='0 0 38.48 32.14'
                    >
                        <title>Twitter</title>
                        <path
                            id='path1_fill'
                            data-name='path1 fill'
                            d='M22.27,7.43a8.12,8.12,0,0,0-5.42,8.17L16.93,17l-1.39-.17A22.52,22.52,0,0,1,2.3,10.24L.47,8.39,0,9.75a8.26,8.26,0,0,0,1.72,8.4c1.11,1.19.86,1.36-1.06.65a3.28,3.28,0,0,0-1.31-.31c-.19.2.47,2.78,1,3.8a9,9,0,0,0,3.81,3.63l1.36.65-1.61,0c-1.56,0-1.61,0-1.45.63C3,29.08,5.22,31,7.67,31.89l1.72.59-1.5.91a15.48,15.48,0,0,1-7.45,2.1,8.05,8.05,0,0,0-2.28.23c0,.28,3.39,1.87,5.37,2.5a23.28,23.28,0,0,0,18.24-2.1A24.61,24.61,0,0,0,31,25.1a29.48,29.48,0,0,0,1.89-8.51c0-1.31.08-1.48,1.64-3a18.38,18.38,0,0,0,1.95-2.18c.28-.54.25-.54-1.17-.06-2.36.85-2.7.74-1.53-.54a8.28,8.28,0,0,0,1.89-3c0-.08-.42.06-.89.31a15.57,15.57,0,0,1-2.45,1l-1.5.48L29.5,8.56a11.3,11.3,0,0,0-2.36-1.25,9.42,9.42,0,0,0-4.87.11Z'
                            transform='translate(1.84 -7.05)'
                            fill='#fff'
                        />
                    </svg>
                </a>

                <a href='https://www.youtube.com/channel/UCtvrGCeFY_YTd9MeutydOWA' target="_blank" className={styles.youtubeLink}>
                    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" width="71.412064" height="50" x="0" y="0" version="1.1" viewBox="0 0 71.412065 50">
                        <title>Youtube</title>

                        <g id="g5" transform="scale(.58824)">
                            <path id="path7" fill="#fff" fill-opacity="1" d="M35.705078 0S13.35386.0001149 7.765625 1.4707031c-3 .8235294-5.4713925 3.2946921-6.2949219 6.3535157C.0001149 13.412454 0 25 0 25s.0001149 11.64637 1.4707031 17.175781c.8235294 3.058824 3.2360984 5.471393 6.2949219 6.294922C13.412684 50.000115 35.705078 50 35.705078 50s22.353171-.000115 27.941406-1.470703c3.058824-.82353 5.471393-3.236098 6.294922-6.294922 1.470588-5.588235 1.470703-17.175781 1.470703-17.175781s.058709-11.64614-1.470703-17.2343752c-.823529-3.0588236-3.236098-5.4713925-6.294922-6.2949219C58.058249-.00011488 35.705078 0 35.705078 0zm-7.117187 14.294922L47.175781 25l-18.58789 10.705078V14.294922z" transform="scale(1.7)"/>
                        </g>
                    </svg>
                </a>
            </div>

            <div className={styles.bottomContainer}>
                <span className={styles.droxicText}><FormattedMessage id={'menu.droxicOod'} /></span>
                <a className={styles.dataProtectionLink} href={resolveUrlPath('privacy-policy')} title="More info"><FormattedMessage id={'menu.policy'} /></a>
            </div>
            <div>
                <a className={styles.dataProtectionLink} href={resolveUrlPath('eu-finance')} title="More info"><FormattedMessage id={'menu.euFinance'} /></a>
            </div>
        </footer>
    );
};

export default MenuFooter;
