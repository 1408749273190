import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clickMainButton, clickMainMenuItem } from '../../actions/index';
import MainButton from '../MainButton';
import MenuContainer from './MenuContainer';
import MenuLanguageBar from './MenuLanguageBar';
import MenuItems from './MenuItems';
import MenuFooter from './MenuFooter';
import styles from './Menu.module.css';

const Menu = props => {
    const mainContainerClasses = classnames(
        styles.mainContainer,
        { [`${styles.mainContainerOpen}`]: props.isOpened }
    );

    return (
        <div className={mainContainerClasses}>
            <MainButton />
            <MenuContainer {...props}>
                <div>
                    <MenuItems {...props} />
                </div>
                <MenuFooter {...props} />
            </MenuContainer>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ clickMainButton, clickMainMenuItem }, dispatch);
}

function mapStateToProps(state) {
    return { isOpened: state.menuState.open };
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
