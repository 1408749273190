// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import './PrivacyPolicy.css';

type Props = {
  config: {
    sliderOptions: Object
  }
};

export const EuFinance = ({ config }: Props) => {
  return (
    <FormattedMessage id="euFinance.page.content">
      {(txt) => (
        <div className="page-content-common" dangerouslySetInnerHTML={{__html: txt}} />
      )}
    </FormattedMessage>
  );
}
