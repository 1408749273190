import React, { useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { gsap, Sine } from 'gsap';
import styles from './MenuItems.module.css';
import MenuLink from '../MenuLink';
import { resolveUrlPath } from '../../../utils/routerUtility';

const MenuItems = props => {
    const aboutRef = useRef(null);
    const workRef = useRef(null);
    const careersRef = useRef(null);
    const contactRef = useRef(null);
    const blogRef = useRef(null);

    useEffect(() => {
        const { isOpened } = props;
        const elements = [aboutRef.current, workRef.current, careersRef.current, blogRef.current, contactRef.current];
        const baseDelay = isOpened ? .19 : .02;
        const delayPerItem = isOpened ? .04 : .02;
        const newOpacity = isOpened ? 1 : 0;
        const paddingLeft = isOpened ? 0 : 50;
        elements.forEach((element, index) => {
            gsap.to(element, .2, {
                delay: baseDelay + (index * delayPerItem),
                opacity: newOpacity,
                paddingLeft: paddingLeft,
                ease:Sine.easeOut
            });
        });
    }, [props.isOpened]);

    return (
        <nav className={styles.container} role="navigation" aria-label="Main">
            <ul className={styles.list} role="menu">
                <li className={styles.listItem} role="menuitem">
                    <MenuLink
                        {...props}
                        ref={aboutRef}
                        label={<FormattedMessage id={'menu.about'} />}
                        url={resolveUrlPath('about')}
                    />
                </li>
                <li className={styles.listItem} role="menuitem">
                    <MenuLink
                        {...props}
                        ref={workRef}
                        label={<FormattedMessage id={'menu.work'} />}
                        url={resolveUrlPath('work')}
                    />
                </li>
                <li className={styles.listItem} role="menuitem">
                    <MenuLink
                      {...props}
                      ref={blogRef}
                      label={<FormattedMessage id={'menu.blog'} />}
                      url='https://blog.droxic.com/'
                    />
                </li>
                <li className={styles.listItem} role="menuitem">
                    <MenuLink
                        {...props}
                        ref={contactRef}
                        label={<FormattedMessage id={'menu.contact'} />}
                        url={resolveUrlPath('contacts')}
                    />
                </li>
            </ul>
        </nav>
    );
};

export default MenuItems;
