export default {
    'bg': {
        'pages': {
            'home': {
                'slides': {
                    'aboutTitle': 'Това е Droxic.',
                    'aboutSubtitle': 'Ние създаваме дигитални изживявания.',
                    'aboutButtonText': 'Разгледайте',
                    'clientsTitle': 'Клиенти.',
                    'clientsSubtitle': 'Глобални марки, енергични стартиращи фирми и други агенции.',
                    'clientsButtonText': 'Вижте Клиентите ни',
                    'expertiseTitle': 'В какво сме добри.',
                    'expertiseSubtitle': 'Дигиталното е нашата страст.',
                    'expertiseButtonText': 'Какво правим',
                    'contactsTitle': 'Неограничен обхват.',
                    'contactsSubtitle': 'Ние сме наистина глобални, с офиси и приятели навсякъде.',
                    'contactsButtonText': 'Контакти'
                },
                'dialogs': {
                    'expertise': {
                        'digitalPlatformsTitle': 'Дигитални платформи.',
                        'digitalPlatformsSubtitle': 'Ние програмираме уебсайтове и дигитални изживявания, които свързват марките с хората.',
                        'mobileApplicationsTitle': 'Мобилни приложения.',
                        'mobileApplicationsSubtitle': 'Ние доставяме нейтив и хибридни мобилни приложения за всяка основна операционна система.',
                        'creativeDirectionsTitle': 'Творческо направление.',
                        'creativeDirectionsSubtitle': 'Всеки проект се води по ясен и креативен път, уникален за нашия клиент.',
                        'brandingTitle': 'Брандиране.',
                        'brandingSubtitle': 'Нашият код, дизайн и копирайтинг изграждат силни марки, използвайки изследвания и стратегия.'
                    }
                }
            },
            'about': {
                'description': "Ние сме проактивна дигитална агенция със стремеж към прецизност, партньор за други агенции и клиенти по целия свят, създавайки брандирани изживявания за уеб и мобилни устройства.",
                'expertiseTitle': 'Експертиза.',
                'expertiseSubtitle': 'Дигитален: Уеб и мобилни.',
                'expertiseButtonText': 'Какво правим',
                'teamTitle': 'Екип.',
                'teamSubtitle': 'Мислители. Създатели. Заедно.',
                'teamButtonText': 'Кои сме ние',
                'clientsTitle': 'Клиенти.',
                'clientsSubtitle': 'Ние сме нищо без нашите клиенти.',
                'clientsButtonText': 'Вижте клиентите ни',
                'referencesTitle': 'Опит.',
                'referencesSubtitle': 'Открийте нашия успех от първа ръка.',
                'referencesButtonText': 'Нашите истории',
                'dialogs': {
                    'expertise': {
                        'digitalPlatformsTitle': 'Дигитални платформи.',
                        'digitalPlatformsSubtitle': 'Ние разработваме уебсайтове и дигитални изживявания, които свързват марките с хората.',
                        'mobileApplicationsTitle': 'Мобилни приложения.',
                        'mobileApplicationsSubtitle': 'Ние доставяме нейтив и хибридни мобилни приложения за всяка основна операционна система.',
                        'creativeDirectionsTitle': 'Творческо направление.',
                        'creativeDirectionsSubtitle': 'Всеки проект се води по ясен и креативен път, уникален за нашия клиент.',
                        'brandingTitle': 'Брандиране.',
                        'brandingSubtitle': 'Нашият код, дизайн и копирайтинг изграждат силни марки, използвайки изследвания и стратегия.'
                    },
                    'team': {
                        'pavelName': 'Pavel',
                        'pavelPosition': 'CEO',

                        'ilianName': 'Ilian',
                        'ilianPosition': 'CTO',

                        'chopyName': 'Chopy',
                        'chopyPosition': 'CPO',

                        'stoyanName': 'Stoyan',
                        'stoyanPosition': 'CPP',

                        'mariName': 'Mari',
                        'mariPosition': 'Business development',

                        'georgeName': 'George',
                        'georgePosition': 'Business development',

                        'sevdaName': 'Sevda',
                        'sevdaPosition': 'Business development',

                        'arminName': 'Armin',
                        'arminPosition': 'Business development',

                        'joostName': 'Joost',
                        'joostPosition': 'Business development',

                        'richardName': 'Richard',
                        'richardPosition': 'Business development',

                        'viliName': 'Vili',
                        'viliPosition': 'Resource Manager',

                        'zoriName': 'Zori',
                        'zoriPosition': 'Account executive',

                        'loraName': 'Lora',
                        'loraPosition': 'HR Manager',

                        'veronikaName': 'Veronika',
                        'veronikaPosition': 'HR Generalist',

                        'ivaName': 'Iva',
                        'ivaPosition': 'HR Generalist',

                        'natName': 'Nat',
                        'natPosition': 'HR Generalist',

                        'borislavaHrName': 'Borislava',
                        'borislavaHrPosition': 'HR Manager',

                        'hristinaName': 'Hristina',
                        'hristinaPosition': 'Head of Marketing',

                        'julianName': 'Julian',
                        'julianPosition': 'Project manager',

                        'valeriaName': 'Valeria',
                        'valeiraPosition': 'Project manager',

                        'alexName': 'Alex',
                        'alexPosition': 'UX/UI',

                        'konstantinName': 'Konstantin',
                        'konstantinPosition': 'Software engineer',

                        'daniName': 'Dani',
                        'daniPosition': 'Software engineer',

                        'galinName': 'Galin',
                        'galinPosition': 'Software engineer',

                        'kirilName': 'Kiril',
                        'kirilPosition': 'Software engineer',

                        'lyuboName': 'Lyubo',
                        'lyuboPosition': 'Software engineer',

                        'emiName': 'Emi',
                        'emiPosition': 'Software engineer',

                        'zdravkoName': 'Zdravko',
                        'zdravkoPosition': 'Software engineer',

                        'radovanName': 'Radovan',
                        'radovanPosition': 'Branch manager',

                        'tsvetoName': 'Tsveto',
                        'tsvetoPosition': 'Software engineer',

                        'krisiName': 'Krisi',
                        'krisiPosition': 'Software engineer',

                        'borislavaName': 'Borislava',
                        'borislavaPosition': 'Software engineer',

                        'ivanvName': 'Ivan V',
                        'ivanvPosition': 'Software engineer',

                        'kristianName': 'Kris',
                        'kristianPosition': 'Software engineer',

                        'slavName': 'Slav',
                        'slavPosition': 'Software engineer',

                        'dimitarName': 'Dimitar',
                        'dimitarPosition': 'Software engineer',

                        'tsvetanName': 'Tsvetan',
                        'tsvetanPosition': 'Software engineer',

                        'dautName': 'Daut',
                        'dautPosition': 'Software engineer',

                        'dimiName': 'Dimi',
                        'dimiPosition': 'QA Specialist',

                        'bojoName': 'Bojo',
                        'bojoPosition': 'QA Specialist',

                        'foriName': 'Fori',
                        'foriPosition': 'QA Specialist',

                        'chochoName': 'Chocho',
                        'chochoPosition': 'QA Specialist',

                        'vladiName': 'Vladi',
                        'vladiPosition': 'Sys Admin',

                        'valetoName': 'Valentina',
                        'valetoPosition': 'Office Manager',

                        'kristinaSavovaName': 'Kristina',
                        'kristinaSavovaPosition': 'Office Manager',

                        'dobriName': 'Dobri',
                        'dobriPosition': 'Software engineer',

                        'dusanName': 'Dušan',
                        'dusanPosition': 'Software engineer',

                        'darkoName': 'Darko',
                        'darkoPosition': 'Software engineer',

                        'ivanchName': 'Ivan',
                        'ivanchPosition': 'Software engineer',

                        'dimitardName': 'Dimitar',
                        'dimitardPosition': 'Software engineer',

                        'milenaName': 'Milena',
                        'milenaPosition': 'Office Manager Niš',

                        'danielaName': 'Daniela',
                        'danielaPosition': 'Account Executive Assistant',

                        'ninaName': 'Nina',
                        'ninaPosition': 'Software engineer',

                        'vanyoName': 'Vanyo',
                        'vanyoPosition': 'UX/UI',

                        'petarName': 'Petar',
                        'petarPosition': 'Software engineer',

                        'biljanaName': 'Biljana',
                        'biljanaPosition': 'Software engineer',

                        'miroljubName': 'Miroljub',
                        'miroljubPosition': 'Software engineer',

                        'radosName': 'Radoš',
                        'radosPosition': 'Software engineer',

                        'dejanName': 'Dejan',
                        'dejanPosition': 'QA specialist',

                        'lazaName': 'Laza',
                        'lazaPosition': 'Software engineer',

                        'aleksandarName': 'Aleksandar',
                        'aleksandarPosition': 'Software engineer',

                        'markoName': 'Marko',
                        'markoPosition': 'Software engineer',
                    },
                    'references': {
                        'realityBytesName': 'Andreas  Kuno  Kuntze',
                        'realityBytesPosition': 'CEO',
                        'realityBytesCompany': 'Reality  Bytes  Neue  Medien',
                        'realityBytesDescription': 'Започнахме съвместна работа с Дроксик преди две години. Нашето партньорство се разрасна до цялостни услуги за разработка. Те винаги са на върха на технологичните иновации. Мога да им се доверя напълно.',


                        'lieferheldName': 'Matthias Pfaff',
                        'lieferheldPosition': 'Head of customer experience',
                        'lieferheldCompany': 'Lieferheld',
                        'lieferheldDescription': 'Имах разработчици от Droxic, работещи в моя екип за разработка на Android, iOS, Python и JS. Всички специалисти от Дроксик бяха много мили, компетентни и с тях беше забавно да се работи. Droxic като компания винаги е била супер надеждна и е предоставяла много гъвкавост като партньор, когото бих препоръчал и препоръчвам винаги и на всеки.',


                        'oliverUkName': 'Martin  Nartey',
                        'oliverUkPosition': 'Digital  Project  Lead',
                        'oliverUkCompany': 'Oliver  UK',
                        'oliverUkDescription': "Дигиталната експертиза на Droxic е несравнима. Работейки с тях по демонстрационен дигитален проект за един от най-големите ни клиенти, те надминаха очакванията за всички изисквания, които изпратихме, и бяха изключително гъвкави и способни да планират нуждите на нашия проект. Те помогнаха да се организира един сложен проект и нашият клиент беше много възхитен от крайния продукт.",


                        'kweakName': 'Baris  Karatas',
                        'kweakPosition': 'CTO',
                        'kweakCompany': 'Kweak',
                        'kweakDescription': "Бих искал да подчертая нашата признателност за надеждното сътрудничество и уменията, които вашият екип внесе в нашия проект. Съвместната ни работа беше страхотна и определено ще препоръчам Droxic и неговия страхотен екип на другите. Надявам се да се свържем с вас в бъдеще отново.",


                        'exposureName': 'Rory Oliver',
                        'exposurePosition': 'Production Director',
                        'exposureCompany': 'Exposure UK',
                        'exposureDescription': 'Работих с Droxic, когато бях производствен директор в Exposure и мога съвсем честно да кажа, че те са най-добрият аутсорсинг разработчик, с когото някога съм работил. Започнахме със среден по размер, но изключително сложен проект. Можа да кажа, че те се представиха на изключително ниво. През следващите 2 години работихме по редица проекти и всеки път качеството, обслужването и доставката бяха ненадминати. Не бих се поколебал да ги използвам отново. Освен това са страхотни хора и е удоволствие да се работи с тях.',


                        'icaewName': 'Adam  Thompson',
                        'icaewPosition': 'UX  Manager',
                        'icaewCompany': 'ICAEW',
                        'icaewDescription': "В началото Droxic ни помагаха да допълним нашия базиран в Обединеното кралство ресурс за разработка. Бяхме впечатлени както от качеството на тяхната работа, така и от гъвкавостта им да увеличават и намаляват броя на разработчиците, работещи по нашите проекти, в кратки срокове.",


                        'siiliName': 'Matti Kiviluoto',
                        'siiliPosition': 'CMS Team Lead',
                        'siiliCompany': 'Siili Solutions Plc',
                        'siiliDescription': "Бяхме изключително доволни от професионализма и компетентността на разработчиците, с които сме работили. Нашите бизнес отношения винаги са били гладки, прозрачни и коректни. Многократно сме били изненадвани от способността на Droxic да предоставя нови висококвалифицирани разработчици в толкова разнообразни области на развитие. Мога искрено да препоръчам Droxic като партньор за разработка на софтуер!",


                        'initName': 'Klaus Santel',
                        'initPosition': 'Director Development',
                        'initCompany': 'Init',
                        'initDescription': "Дълги години Droxic е наш надежден партньор, когато става въпрос за поддръжка на нашите уеб проекти. Те са много гъвкави и винаги се ангажират да отговорят на нашите очаквания. Droxic ни помогна в много критични ситуации. Винаги е удоволствие да работим с тях. Комуникацията е на много високо ниво и при проблеми винаги сме намирали решение заедно много бързо."
                    }
                }
            },
            'careers': {
                'detailsButtonLabel': 'View more',
                'frontPageTitle': 'We want talent.',
                'frontPageDescription': 'Makers everywhere, keep scrolling for the opportunities.',
                'wantToJoinTitle': 'Join us.',
                'applyNowButton': 'Apply',
                'gdprText': 'By applying to this position, I give my consent that the personal data included in my CV/resume/motivational letter will be processed in accordance with Droxic\'s Data Protection Policy and for the mentioned period of time (<a>Data Protection Policy</a>)',

                'jobOffer1': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Sitecore Software Developer.',
                    'location': 'Sofia / Niš',


                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'Proven knowledge and experience with CMS systems (Sitecore, Sitefinity or Umbraco).',
                    'requiredSkillsListItem2': '3 + years of experience with .Net.',
                    'requiredSkillsListItem3': 'Object oriented programming experience.',
                    'requiredSkillsListItem4': 'Strong problem-solving, written and verbal communication skills.',
                    'requiredSkillsListItem5': 'Capability to work on short term international projects (3 months, 6 moths, etc…).',
                    'requiredSkillsListItem6': 'Flexibility and a sense of humour.',


                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'Work on different and interesting Sitecore based .NET projects.',
                    'responsibilitiesListItem2': 'Prove yourself as a high professional.',
                    'responsibilitiesListItem3': 'Be in a team to be proud of.',
                    'responsibilitiesListItem4': 'Be in charge of international business.',


                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer2': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Senior Front-end Expert.',
                    'location': 'Sofia / Niš',

                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'JavaScript is a children’s’ toy for you.',
                    'requiredSkillsListItem2': 'You have extensive experience in Front-End / Full Stack web development.',
                    'requiredSkillsListItem3': 'You have particular affinity for React.js and/or Vue.js, and have participated in software projects using at least one of these technologies.',
                    'requiredSkillsListItem4': 'You have solid knowledge in CSS3 (including SASS/LESS) and HTML5.',
                    'requiredSkillsListItem5': 'You are always open to expanding your knowledge and can easily pick up on a new framework.',
                    'requiredSkillsListItem6': 'You have proven experience using responsive design principles.',


                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'You will participate in interesting and challenging client projects, working both independently and as part of a team.',
                    'responsibilitiesListItem2': 'You will develop sophisticated visual and functional front-end components.',
                    'responsibilitiesListItem3': 'You will build reusable, scalable code; following best practices.',
                    'responsibilitiesListItem4': 'You will work with some of the hottest technologies around.',


                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer3': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Business Development Manager.',
                    'location': 'Sofia / Niš',

                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'A few years of progressive selling experience to international clients in the digital field.',
                    'requiredSkillsListItem2': 'Working knowledge of the web and mobile development services landscape.',
                    'requiredSkillsListItem3': 'Existing network of end client relationships in Western Europe.',
                    'requiredSkillsListItem4': 'Self-starter, excellent negotiator and a team player.',
                    'requiredSkillsListItem5': 'Excellent presentation, phone and written communication skills.',
                    'requiredSkillsListItem6': 'Highly proficient in English.',
                    'requiredSkillsListItem7': 'Second language proficiency - Swedish/Norwegian/Danish/Finnish or German.',
                    'requiredSkillsListItem8': 'Demonstrated passion for web and mobile innovations.',

                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'Aim for growth in market share, volume, turnover and profit.',
                    'responsibilitiesListItem2': 'Approach new prospects and offer Droxic’s services.',
                    'responsibilitiesListItem3': 'Generate new relationships with prospective clients.',
                    'responsibilitiesListItem4': 'Understand customer needs and highlight the features and the benefits of our quality-oriented services.',
                    'responsibilitiesListItem5': 'Work close with internal software development department and PMs to develop strategies and offers that meet the brief of the client.',
                    'responsibilitiesListItem6': 'Identifying further opportunities to serve the existing clients.',
                    'responsibilitiesListItem7': 'Maintain up to date knowledge on trends, issues and opportunities within territory.',


                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer4': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Java senior developer.',
                    'location': 'Sofia',


                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'You have practical experience in JSE based web development technologies.',
                    'requiredSkillsListItem2': 'You have practical experience in Spring & Spring Boot.',
                    'requiredSkillsListItem3': 'You have knowledge in CI/CD methodology / tooling (Maven, Jenkins, GitLab-CI/CD, Bamboo).',
                    'requiredSkillsListItem4': 'You have knowledge in container technologies (Docker, Kubernetes, OpenShift/OKD).',
                    'requiredSkillsListItem5': 'You have knowledge in relational Databases (MySQL, Postgres, Oracle).',
                    'requiredSkillsListItem6': 'Knowledge in requirements engineering / technical specifications / scope management.',
                    'requiredSkillsListItem7': 'You have knowledge in architectural approaches.',
                    'requiredSkillsListItem8': 'You have experience in agile methodologies.',
                    'requiredSkillsListItem9': 'It’s a plus if you have knowledge in architectural approaches.',
                    'requiredSkillsListItem10': 'It’s a plus if you have experience in software testing technologies.',
                    'requiredSkillsListItem11': 'It’s a plus if you have knowledge in customizing Content Management Systems (esp. CoreMedia CMS).',
                    'requiredSkillsListItem12': 'It’s a plus if you have knowledge in further Java frameworks (JEE, Quarkus, Wicket, Camunda, Keycloak, form.io, JSF).',
                    'requiredSkillsListItem13': 'You have good written and spoken language skills (English).',

                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'You will participate in interesting and challenging client projects, working both independently and as part of a team.',
                    'responsibilitiesListItem2': 'You will build reusable, scalable code, following best practices.',
                    'responsibilitiesListItem3': 'You will work with some of the hottest technologies around.',

                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer5': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Application Support Specialist.',
                    'location': 'Sofia',


                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'You have minimum 3-5 years of experience in technical consulting and analytics.',
                    'requiredSkillsListItem2': 'You have good understanding of the issues that an end-user faces with corporate IT Service Management.',
                    'requiredSkillsListItem3': 'You have analytical approach to recurring problems and collected data.',
                    'requiredSkillsListItem4': 'You have good communication skills and ability to maintain good relationship with customers.',
                    'requiredSkillsListItem5': 'You have positive attitude and great interpersonal skills, with a “can do” attitude.',
                    'requiredSkillsListItem6': 'You have excellent written and spoken English language skills.',
                    'requiredSkillsListItem7': 'It’s an advantage if you have relevant software industry experience in any of the following: IT systems, enterprise or infrastructure management, application development and management, security, and/or analytics.',

                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'You will assist with general inquiries, product support, and troubleshooting via phone, e-mail, and chat.',
                    'responsibilitiesListItem2': 'You will track customer issues using a ticketing system to ensure quick resolution and proper follow-up and documentation.',
                    'responsibilitiesListItem3': 'You will collect data and draw conclusions from it, identifying problems and suggest resolutions.',
                    'responsibilitiesListItem4': 'You will analyze customer needs, goals, strategic plans and constraint in order to advice the best possible use of the platform.',
                    'responsibilitiesListItem5': 'You will have to understand Customer Digital Experience goals, strategic plans and constraints in order to advise on the best use of the product.',
                    'responsibilitiesListItem6': 'You will stay current on product development/releases to a level required for the above activities.',

                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer6': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Technical Project Manager.',
                    'location': 'Sofia / Niš',


                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'At least 4 - 5 years of technical delivery experience with a demonstrated PM track record in web projects.',
                    'requiredSkillsListItem2': 'Full understanding of how to manage an end-to-end technical  project lifecycle, from idea inception through development and launch.',
                    'requiredSkillsListItem3': 'Demonstrated experience simultaneously working on multiple projects with multiple partners.',
                    'requiredSkillsListItem4': 'Experience with both Waterfall and Agile.',
                    'requiredSkillsListItem5': 'Strong background in different aspects of technology including object-oriented programming languages. Experience in .NET, PHP or JavaScript is a plus.',
                    'requiredSkillsListItem6': 'Experience facilitating rapid application development teams to deliver technology solutions on time, on budget, and according to client requirements.',
                    'requiredSkillsListItem7': 'Experience using an agile delivery management platform, JIRA preferred.',
                    'requiredSkillsListItem8': 'Bachelors/Masters in Computer Science or a related field is a plus.',
                    'requiredSkillsListItem9': 'Fluent English language.',


                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'Directing and coordinating multiple web and mobile projects from initiation phase through to implementation phase.',
                    'responsibilitiesListItem2': 'Translation of business requirements into technology solutions and participation in the analysis, evaluation and choice of development toolchains.',
                    'responsibilitiesListItem3': 'Working closely with cross-functional teams in order to define scope, goals and deliverables in alignment with our clients’ goals and our business strategy.',
                    'responsibilitiesListItem4': 'Project estimations and resource planning.',
                    'responsibilitiesListItem5': 'Tracking and reporting on progress. Aiming for delivery of quality solutions on time.',
                    'responsibilitiesListItem6': 'Proactively managing changes and improvements in project scope, identifying issues and driving for resolution.',
                    'responsibilitiesListItem7': 'Managing relationships with new clients and partners.',
                    'responsibilitiesListItem8': 'Ensuring that all stakeholders are aligned towards a common goal by meeting and receiving feedback on an ongoing basis.',
                    'responsibilitiesListItem9': 'Organizing and facilitating of daily stand-ups and retrospectives at the end of each sprint. Creating an environment in which the team is continuously learning and encouraged to reflect and improve their delivery capacity.',
                    'responsibilitiesListItem10': 'Coordinating communication between teams and facilitating productive discussions.',
                    'responsibilitiesListItem11': 'You may also have people management responsibilities.',


                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer7': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Data Engineer.',
                    'location': 'Sofia',

                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'You have 2+ years of industry data engineering experience, including experience in ETL design, implementation and maintenance.',
                    'requiredSkillsListItem2': 'You have proven experience in the data warehouse space, as well as schema design.',
                    'requiredSkillsListItem3': 'You are very good in SQL and at least one modern programming language (Python, Java, etc).',
                    'requiredSkillsListItem4': 'You have profound knowledge of Kubernetes.',
                    'requiredSkillsListItem5': 'You are experienced in designing data solutions within cloud platforms such as AWS (preferred), Google Cloud Platform or Azure.',
                    'requiredSkillsListItem6': 'You have strong knowledge and experience with some distributed data processing technology like Redshift/BigQuery/Snowflake/Spark or similar.',
                    'requiredSkillsListItem7': 'Bonus: If you have experience with Streaming data AWS Kinesis, Kafka or RabbitMQ.',
                    'requiredSkillsListItem8': 'Bonus: if you have Bachelor’s degree in a technical field (computer science, engineering, mathematics,  informatics); advanced degree preferred (or equivalent experience).',
                    'requiredSkillsListItem9': 'Bonus: if you have knowledge of visualisation tools such as Looker, Tableau, etc.',
                    'requiredSkillsListItem10': 'Bonus: if you have knowledge of Snowplow.',

                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'You will interface with engineers, product managers and data scientists to understand data needs and implement robust and scalable solutions.',
                    'responsibilitiesListItem2': 'You will work directly with analytics engineers to implement robust and reusable data models.',
                    'responsibilitiesListItem3': 'You will build and maintain data pipelines.',
                    'responsibilitiesListItem4': 'You will ensure data quality and accessibility for all types of data.',
                    'responsibilitiesListItem5': 'You will design and implement ETL processes for new and existing data sources.',
                    'responsibilitiesListItem6': 'You will identify and rectify data quality issues.',
                    'responsibilitiesListItem7': 'You will import data from APIs and several different data sources.',
                    'responsibilitiesListItem8': 'You will write good documentation—for other data engineers, as well as for other colleagues.',

                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                }
            },
            'work': {
                'project1': {
                    'name': 'Lieferheld',
                    'technologies': 'HTML5, CSS3, JavaScript, jQuery, Backbone.JS, React.JS, NodeJS, Immutable.JS, ExpressJS, PhoneGap, PostgreSQL, Elastic search, Django, Jenkins',
                    'description': 'В продължение на три години помагахме на тази водеща в света компания за доставка на храна да постигне изгключителни успехи. Благодарение на дългосрочните ни отношения, успяхме да разработим сложни дигитални платформи за марката, управлявайки всички нейни световни представители.'
                },
                'project2': {
                    'name': 'ICAEW',
                    'technologies': 'Sitecore CMS',
                    'description': 'Горди сме, че сме натоварени с отговорността за текущата поддръжка и разработването на нови функции за известната професионална организация за счетоводители, която включва повече от 150 000 членове по целия свят.'
                },
                'project3': {
                    'name': 'SupperApps',
                    'technologies': 'Linux, Apache, MySQL and PHP (LAMP), Symfony 2, Custom-built CMS system, PhoneGap, Ajax',
                    'description': 'Разработихме и поддържаме три мобилни приложения (Клиенти, Готвачи и Шофьори), представляващи една цялостна платформа за домашно приготвени и здравословни ястия. Всичко процеси се управляват от една унифицирана CMS, разработена с основен фокус върху потребителското изживяване.'
                },
                'project4': {
                    'name': 'IAM',
                    'technologies': 'Microsoft.NET, C #, ASP.NET, Sitefinity CMS, IIS, Grunt, SASS, Bootstrap, JSON, GoogleMaps API',
                    'description': 'Разработихме основния уебсайт, уеб магазин и няколко микросайта за Institute of Advanced Motorists. Високите нива на персонализирано клиентско изживяване и клиентска автономност бяха ключови цели при създаването на платформата.'
                },
                'project5': {
                    'name': 'Ally',
                    'technologies': 'iOS Development, Swift',
                    'description': 'Приложението Allly помага на клиентите да планират пътуванията си по най-удобния начин, като сравнява цена, време и методи на транспорт. Нашата разработка беше насочена към осигуряване на обединяване на всички тези фактори и рационализиране на потребителското изживяване.'
                },
                'project6': {
                    'name': 'Mamut',
                    'technologies': 'Wordpress, HTML, CSS, JavaScript',
                    'description': 'Mamut е уникално цифрово приключение, което дава на своите посетители достъп до най-впечатляващите планини в света. Беше необходимо да подходим също толкова приключенски, колкото беше и самата визия на проекта. Проект 360 беше наистина успешен, пренасяйки екстремното на екраните на хората.'
                },
                'project7': {
                    'name': 'Raiffeisen Bank',
                    'technologies': 'Dojo, jQuery, AJAX, NPM/Bower, Grunt, SASS/SCSS, Bootstrap/ZURB,  Foundation, Handlebars, jQuery, jQuery UI, Classy, Require.js',
                    'description': 'Концепцията на този проект беше да предостави персонализиран център, който отговаря на изискванията на клиентите с графична система, която поддържа две комуникативни нива: емоционално и информационно, със завладяваща фотография и лесен за използване интерфейс.'
                },
                'project8': {
                    'name': 'Mr & Ms Smith',
                    'technologies': 'PHP, Symfony, Zend 2',
                    'description': 'Благодарение на нашата работа, проектът Mr & Ms Smith еволюира от скромна платформа за резервации през 2003 г. в глобален туристически клуб, какъвто е днес, с над милион членове в повече от сто държави.'
                },
                'project9': {
                    'name': 'Le Mont Botanique',
                    'technologies': 'Drupal 7 E-commerce, HTML, CSS, JavaScript',
                    'description': 'Нашият електронен магазин направи революция в процеса на поръчване, като подобри клиентското изживяване от първия преглед до плащането. Структурата позволява пълен контрол на процесите като включва счетоводство, отпечатване на отчети и фактури, досиета и многоезична поддръжка.'
                },
                'project10': {
                    'name': 'Bubble Nova',
                    'technologies': 'Cocos2d',
                    'description': 'Разработката на тази игра включва създаването на уникалната графика и герои. Bubble Nova е приключенска игра, която превежда играчите през редица различни зони и нива.'
                },
                'project11': {
                    'name': 'CalConvert',
                    'technologies': 'iOS Development, Swift, PHP, JSON',
                    'description': 'Това приложение е безплатен калкулатор, конвертор на валута и единици за iOS и WatchOS. Неговият класически дизайн се съсредоточава върху функционалността и потребителското изживяване, за хора от всички възрасти, с много теми за персонализиране.'
                },
                'project12': {
                    'name': 'Timer',
                    'technologies': 'iOS Development, Swift',
                    'description': 'Timer е приложение, което помага на потребителя да оптимизира и организира времето си. Разработихме удобното приложение, така че хората навсякъде да могат да задават надеждни таймери, напомняния, обратно броене и аларми, както и да създават списъци със задачи и да управляват задачи.'
                },
                'project13': {
                    'name': 'Spooky Hill Game',
                    'technologies': 'Cocos2d',
                    'description': 'Spooky Hill е аркадна игра, проектирана и разработена от мобилния екип на Droxic. Нашата първостепенна цел в този проект беше да забавляваме играчите по време на празниците с непрекъснато актуализирани герои и безкрайно забавление.'
                },
                'project14': {
                    'name': 'Gothenburg',
                    'technologies': 'Android Development',
                    'description': 'Създадохме това приложение-пътеводител за град Гьотеборг, включващо интересни места, ресторанти, хотели, GPS позициониране и други локации, представляващи интерес за потребителя, наред с редица други важни и завладяващи функции, от които един турист би се нуждаел и би оценил.'
                },
                'project15': {
                    'name': 'Affligem',
                    'technologies': 'Sitecore, HTML, CSS, JavaScript',
                    'description': 'Брандирането беше централна цел за нашия работен процес за Affligem. От етапите на проучване и стратегия до стартирането на сайта, ние се погрижихме да отразяваме наследството на Affligem и постоянния ангажимент за създаване на най-добрите бири.'
                },
                'project16': {
                    'name': 'Zukunf Läuft',
                    'technologies': 'Phonegap, Cordova, Angular.JS, Ionic',
                    'description': 'Това приложение се основава на научен въпросник, който помага на студентите да управляват своите очаквания и талант с крайната цел да намерят работата, която им подхожда най-добре. Ние работихме с акцент върху яснотата, особено върху това как информацията се представя на потребителя.'
                },
                'project17': {
                    'name': 'BraveZone',
                    'technologies': 'Drupal 7, HTML, CSS, JavaScript',
                    'description': 'Работихме в тясно сътрудничество с екипа на BraveZone, за да разработим неговото портфолио от уебсайтове. Те са една от водещите рекламни агенции в Швеция и беше страхотно изживяване да се обединим с нейните дизайнери и творци, за да предоставим смело, ново портфолио.'
                },
                'project18': {
                    'name': 'Médecins Sans Frontières',
                    'technologies': 'XML, PHP, MySQL, Apache, JavaScript',
                    'description': 'В продължение на години ползотворна работа с MSF, успяхме да завършим редица предизвикателни Drupal проекти. Нашите опитни консултанти помогнаха да се разрушат цифровите граници за „Лекари без граници“.'
                },
                'project19': {
                    'name': 'GASAG',
                    'technologies': 'MVC 5, Sitecore 9.1.0, EXM (Integration with SAP), Multisite, Continues Integration - TeamCity, Octopus Deploy, HTML/HTML5, JavaScript, SCSS/CSS, Vue.JS, Gulp, Require.JS, JQuery, Node.JS for StoryBook, RestAPI, Docker',
                    'description': 'Групата GASAG разполага с 15 сайта и портала. Те обслужват нуждите както на потребителите, така и на бизнеса. GASAG е една от водещите и най-стари компании, работещи в енергийния сектор в Германия и особено в Берлин. Компанията направи пълно повторно стартиране на основния корпоративен сайт през 2020 г. и в момента работи върху останалите портали и сайтове.'
                },
                'project20': {
                    'name': 'MassUp',
                    'technologies': 'PHP, MySQL, Magento 2, HTML5, CSS3, jQuery, React.JS, JavaScript, LESS',
                    'description': 'MassUp ви позволява да започнете да продавате незабавно. Иновативните продукти са с наждно, най-съвременно техническо решение, което може да бъде включено във всички канали за продажба на клиенти и предоставя високоефективни услуги. Системата е разработена като вътрешно решение, за да предостави на други дистрибутори възможността да препродават застраховки с помощта на платформата MassUp.'
                },
                'project21': {
                    'name': 'HMD',
                    'technologies': 'GraphQL, Typescript, React, Next.js, Node.js, Emotion, Contentful, Azure, Akamai, Varnish, Nginx, Hotjar, Open Graph, Web-vitals, Module Federation, Google Analytics, Google Optimize',
                    'description': 'HMD Global е независима финландска компания, единственият голям европейски доставчик на смартфони, който разпространява телефони и таблети Nokia по света. В допълнение към устройствата и аксесоарите, те предлагат набор от услуги за корпоративни клиенти. Работейки в тясно сътрудничество с вътрешния екип на HMD, екипът на Droxic помогна за препроектирането и разработването на техния нов корпоративен уебсайт и свързаните портали за услуги за подобрено съдържание, използваемост, ангажираност и потенциал за генериране на потенциални клиенти, с подобрени операции.'
                }
            },
            'news': {
                'detailsButtonLabel': 'View more',
                'frontPageTitle': 'News page title.',
                'frontPageDescription': 'Here we need a text for the News section.',

                'newsItem1': {
                    'title': 'Bees become harmful.',
                    'location': 'Berlin',

                    'slideTitle1': 'Golden eagles face bright future.',
                    'slideTitleDescription1': 'The breakthrough will help conservationists understand how the bird’s DNA allows them to survive in various habitats, so they can make reintroductions and translocations more successful.',

                    'slideTitle2': 'Female monkeys don\'t trust males.',
                    'slideTitleDescription2': 'Female monkeys are reluctant to follow the example of males even when they would obviously benefit from doing so, new research has found.',
                },
                'newsItem2': {
                    'title': 'Immune cell tunnels.',
                    'location': 'Sofia',

                    'slideTitle1': 'Golden eagles face bright future.',
                    'slideTitleDescription1': 'The breakthrough will help conservationists understand how the bird’s DNA allows them to survive in various habitats, so they can make reintroductions and translocations more successful.',

                    'slideTitle2': 'Female monkeys don\'t trust males.',
                    'slideTitleDescription2': 'Female monkeys are reluctant to follow the example of males even when they would obviously benefit from doing so, new research has found.',
                },
            }
        },
        'menu': {
            'about': 'За нас',
            'work': 'Проекти',
            'contact': 'Контакти',
            'news': 'Views',
            'careers': 'Careers',
            'blog': 'Блог',
            'followUs': 'Последвай ни',
            'droxicOod': '2023 Дроксик ООД |',
            'policy': ' Политика за защита на данните',
            'euFinance': 'ЕС финансиране',
            'logoTooltip': 'Начална страница',
            'openMenuTooltip': 'Отвори меню',
            'closeMenuTooltip': 'Затвори меню'
        },
        'euFinance': {
            'page': {
                content: `
                    <div class="eu-image-container">
                        <div class="eu-image-wrapper"><img src="/eu_image1.png"></div>
                        <div class="eu-image-wrapper"><img src="/eu_image2.png"></div>
                    </div>
                    <section class="eu-text-wrapper">
                        <p>
                        На 10.04.2023г.  ДРОКСИК ООД сключи договор за безвъзмездна финансова помощ,   финансиран по Национален план за възстановяване и устойчивост, по процедура: Технологична модернизация.
                        </p>
                        <p>
                        Общата стойност на проекта е 188 450.00 лв., от които 94 225.00лв. безвъзмездно финансиране и  94 225.00лв. самофинансиране от бенефициента.
                        </p>
                        <p>
                        Цел  на проекта: Да се постигне разширяване на производствения капацитет на компанията, като се инвестира в ново високотехнологично оборудване.
                        </p>
                        <p>
                        Начало на проекта: 10.04.2023г.
                        </p>
                        <p>
                        Край на проекта: 10.04.2024г.
                        </p>
                    </section>
                `
            }
        },
        'privacy': {
            'text': 'We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies. <a>More info</a>',
            'buttonText': 'Got it!',
            'page': {
                content: `
                    <h1 class="page-content-common-heading">DATA PROTECTION & COOKIE POLICY</h1>

                    <div class="page-content-common-wrapper">
                        <section>
                            <p>Last uptdate: 25th of May, 2018 /with respect to the General Data Protection Regulation/</p>
                            <p>This Data Protection & Cookie Policy provides general information about us and our privacy policy and cookie use of droxic.com ("website"/"web page").</p>
                        </section>

                        <section>
                            <h2>ABOUT DROXIC LTD.</h2>
                            <p>We are Droxic Ltd. a Bulgarian company, with UIC (Bulgarian Business ID): BG202224741 and registered address at: Sofia, 62B Rodopski Izvor Str. fl.1 apt.4 ("Droxic"). We understand and appreciate the importance of your personal data and its protection as part of your privacy. We will duly protect the integrity of your personal data, whether concerning you independently or in relation to your company, services or products.</p>
                            <p>Please do not hesitate to contact us regarding your personal data at: <a href="mailto:privacy@droxic.com" title="Mail us">privacy@droxic.com</a></p>
                        </section>

                        <section>
                            <h2>DO WE COLLECT YOUR PERSONAL DATA VIA OUR WEBSITE?</h2>
                            <p>We generally do not seek to collect any personal data while using our website. We do not have a contact form and we do not have any registration forms. The contact details about you constitute personal data and therefore we have only listed our contact details without asking you for specific personal data (like names, e-mail, phone number,etc.). Therefore, the information that you will share with us if you would like to get in touch depends solely on your decision and we will process it for the specific purpose of addressing your inquiry and being able to response.</p>
                            <p>We shall use your personal data only for our correspondence and it will be irrevocably erased after no further responses are expected from any side.</p>
                        </section>

                        <section>
                            <h2>DO WE USE COOKIES?</h2>
                            <p>A cookie is a small text file that a website saves on your computer or mobile device when you visit our website. Droxic may gather information through files such as cookies or log files on the browsing habits. Cookies are files by the website and stored on the user's browser through a web server for the maintenance of the browsing session by storing the IP address of the user (of his or her computer) and other possible browsing data.</p>
                            <p>Droxic uses only web analytics cookies. We use them in order to to measure and analyse user browsing on <a href="http://www.droxic.com">www.droxic.com</a>. Analytics cookies allow us to monitor and analyse the behaviour of users on our website. Web analysis does not allow information like your names or email address to be obtained. The only information obtained relates to the number of users that access the website, the number of pages seen, the frequency and repetition of the visits, their duration, the browser used, the operator providing the service, the language, the device used or the city to which the IP is assigned.</p>
                            <p>Droxic uses the described cookies via Google Analytics (a service, provided by Google Inc.). Google Analytics collects first party cookies, as described above. The information collected using these cookies is sent to Google and is used to evaluate how our web page is being used (for statistics purposes). We will not associate any data gathered with any personally identifying information. We will not link or seek to link any cookie information with any IP address or with the identity of a computer user. Besides the analytics and statistics purpose, Droxic might use advertising cookies to deliver ads that we believe are more relevant to you and your interests. Droxic uses the services of Google and you can read more about their policies here:</p>
                            <ul>
                                <li><p><a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">Google Analytics Cookie Usage on Websites</a></p></li>
                                <li><p><a href="https://policies.google.com/privacy">Privacy Policy – Privacy & Terms – Google</a></p></li>
                            </ul>
                            <p>You can control or delete cookies if you wish - through the options of your browser. You can delete all cookies that are already on your computer and you can set most browsers to prevent them from being placed. Be informed that doing so may prevent you from taking full advantage of the website.</p>
                        </section>
                        
                        <section>
                            <h2>DO WE HAVE THE RIGHT TO PROCESS PERSONAL DATA?</h2>
                            <p>Yes, we do and we strictly obey the rules of the General Data Protection Regulation when processing personal data. Most often we process personal data on the following legal grounds:</p>
                            <ul>
                                <li><p>We process it just because we are legally obliged to do so - when processing is necessary for compliance with a legal obligation;</p></li>
                                <li><p>We process it just because we cannot perform our contractual obligations without processing it;</p></li>
                                <li><p>We process it for the purposes of the legitimate interests, pursued by Droxic (for example: marketing).</p></li>
                                <li><p>We process it when the data subject has given us his/her consent to process it.</p></li>
                            </ul>
                            <p>We may also process personal data on a different occasion but only when the processing is permitted by the specific legal grounds, as set out in the General Data Protection Regulation.</p>
                            <p>Sometimes we might have access to personal data which is collected by our clients (the personal data controllers). If Droxic is acting in the capacity of personal data processor, Droxic shall always follow the processing instructions given by the client and also the obligations under the General Data Protection Regulation.</p>
                        </section>
                        
                        <section>
                            <h2>HOW DO WE PROCESS PERSONAL DATA OF OUR JOB APPLICANTS ("CAREERS" SECTION)?</h2>
                            <p>By submitting your personal data you are consenting to DROXIC holding and using it in accordance with this Data Protection & Cookie Policy. We collect and process information that you provide when you apply for a job. Such personal details usually contain: your name, your address, your e-mail, your date of birth, qualifications, employment history and basically everything else which you have decided to include.</p>
                            <p>We may also look for other publicly available information about you that you have published on the internet (e.g. via linkedin.com).</p>
                            <p>We rely on legal grounds for processing the personal data of job applicants - both our legitimate interest to conduct our recruitment process and we also process it in order to take steps at the request of data subject prior to entering into a contract (labour agreement).</p>
                            <p>We use your personal data in order to: consider your application for the role for which you have applied and also to consider it in respect of future open roles at Droxic. Therefore we may keep your personal data for up to 6 months after receiving it.</p>
                            <p>Please be informed that at any time you may request and demand that we erase your personal data (usually included in your CV/Cover letter) and we will always comply with your ask, unless you are already employed at Droxic.</p>
                        </section>
                        
                        <section>
                            <h2>HOW DO WE PROCESS PERSONAL DATA OBTAINED VIA <a href="http://www.linkedin.com">LINKEDIN.COM</a></h2>
                            <p>Our HR recruiters use the social platform <a href="http://www.linkedin.com">linkedin.com</a> for the purposes of building a talent pool within the recruitment process regarding a current open position and future positions at Droxic as well. We may processes only publicly available information which you have posted/uploaded: your name, your work experience, education, skills & certifications, accomplishment, contact information (e-mail or link to linkedin profile).</p>
                            <p>In case that we consider you eligible and appropriate to be a member of our team, Droxic might store the personal data which you have listed on your linkedin profile. We may store such information using Droxic's internal systems or via third-party software (namely: <a href="http://www.podio.com">podio.com</a>, a cloud based service owned and managed by Citrix Systems Inc.: <a href="https://www.citrix.com/about/legal/privacy/">https://www.citrix.com/about/legal/privacy/</a> ). Such personal data may be stored by Droxic for a period of up to 3 years after obtaining it.</p>
                            <p>Each "passive candidate" who we consider as appropriate is being contacted by Droxic and duly informed about the processing of his/her personal data. We respect everyone's privacy and if you prefer us to cease processing the personal data obtained via <a href="http://linkedin.com">linkedin.com</a> or want us to stop contacting you, please inform Droxic's recruiter who has contacted you or write us at: <a href="mailto:privacy@droxic.com" title="Mail us">privacy@droxic.com</a> If you request us to erase your personal data we will always comply with your ask, unless you are already employed at Droxic.</p>
                        </section>
                        
                        <section>
                            <h2>HAVE YOU RECEIVED A LETTER OR A PHONE CALL FROM US WHICH YOU CONSIDER AS "DIRECT MARKETING"?</h2>
                            <p>We may in some occasions process personal data for the purposes of direct marketing. Such processing is either based on our legitimate interest to do so or it is based on the explicit consent of the data subject. If you do not want us to continue contacting you, please feel free to object to this type of processing by writing to: <a href="mailto:privacy@droxic.com" title="Mail us">privacy@droxic.com</a> or simply by clicking the "unsubscribe" link). We will always comply with such requests!</p>
                        </section>
                        
                        <section>
                            <h2>HOW DO WE PROTECT PERSONAL DATA WHICH IS PROVIDED BY OUR CLIENTS?</h2>
                            <p>We are committed to ensuring that the personal data which our clients provide us with is safe and secured. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect - both personal data and business secrets.</p>
                            <p>With respect to art. 28 of the General Data Protection Regulation, we request that an explicit Controller-Processor agreement is signed. We are determined to provide sufficient guarantees to implement appropriate technical and organisational measures in such a manner that processing will meet the requirements of this Regulation and ensure the protection of the rights of the data subject. These safeguards will vary depending on the sensitivity, format, location, amount, distribution and storage of the personal data, and include measures designed to keep personal data protected from unauthorized access.</p>
                        </section>
                        
                        <section>
                            <h2>HOW LONG DO WE KEEP THE PERSONAL DATA?</h2>
                            <p>The data retention periods of the personal data depends on the type of information, the purposes for which it is used and other factors.</p>
                            <p>In general, we will retain your personal information for the length of time reasonably needed to fulfill the purposes for which it was processed and no longer than that (unless the law requires or permits a longer retention period). We will also retain and use your information for as long as necessary to resolve disputes and/or enforce our rights and agreements.</p>
                        </section>
                        
                        <section>
                            <h2>WHAT RIGHTS DO YOU HAVE?</h2>
                            <p>The General Data Protection Regulation grants you numerous rights with respect to your personal data: right of access, correction, erasure, restriction of processing, objection and data portability, request a copy of your personal data, or if not satisfied - you may even lodge a complaint before the Data Protection Authority - https://www.cpc.bg/. We will not require you to pay any fee to accessing your personal information or to exercise any of the other rights and yet we may charge a reasonable fee if your request/requests is/are clearly unfounded or excessive.</p>
                            <p>Any questions or requests related to this Policy shall be addressed at: <a href="mailto:privacy@droxic.com" title="Mail us">privacy@droxic.com</a></p>
                        </section>
                        
                        <section>
                            <h2>CHANGES TO THIS DATA PROTECTION & COOKIE POLICY</h2>
                            <p>Droxic reserves its right to change and update this Policy at any time. We may also notify data subjects or our clients in case we make some substantial updates.</p>
                        </section>
                    </div>
                `
            }
        }
    }
}